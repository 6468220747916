import React from 'react';
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import ItemOption from '../Widget/ItemOption';
import Global, {USER_STATUSES} from '../../utils/Global';
import BaseComponent from '../BaseComponent';
import {statusColor} from '../../utils/Utilities';
import label from '../../message/Label'
/*= (message, okCallback, cancelCallback) =>*/
export default class UpdateUserStatusModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        state.modal= this.props.modal
        state.backdrop= false
        state.user = this.props.user
        state.userStatus = {}
        state.userStatuses = USER_STATUSES
        state.newUserStatus = {}
        state.okCallback = this.props.okCallback
        state.closeCallback = this.props.closeCallback
        state.note = ""
        state.resiCodeMessage=""
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        let modal = this.state.modal
        if(this.state.modal!=props.modal && props.modal){
            let user = props.user
            let userStatus = null
            USER_STATUSES.forEach(value => {
                if(value.name===user.userStatus){
                    userStatus = value
                }
            })
            this.setState({
                modal:props.modal,
                user:user,
                okCallback : props.okCallback,
                closeCallback : props.closeCallback,
                userStatus:userStatus,
                newUserStatus:{},
                note:"",
                resiCodeMessage : "",
            })
        }
    }


    updateUserStatus=()=>{
        let userStatus = this.state.userStatus?this.state.userStatus:{}
        let newUserStatus = this.state.newUserStatus?this.state.newUserStatus:{}
        let note = this.state.note?this.state.note:""
        let user = this.state.user
        let confirmationMessage = label.DoYouWantToUpdateThisUserStatusFrom(userStatus.name, newUserStatus.name)
        this.openConfirmDialog(label.Confirmation, confirmationMessage, ()=>{
            let formData = new FormData();
            formData.append("userId", user.id)
            formData.append("status", newUserStatus.name)
            formData.append("reason", note)
            this.post(Global.API.USER_UPDATE_STATUS, formData, null, response=>{
                if(response.code===200){
                    let verification = response.data
                    this.successToast(response.message)
                    this.setState({
                        modal:false
                    }, () => {
                        if(this.state.okCallback){
                            this.state.okCallback(verification)
                        }
                    })
                }
            }, true, true)
        })
    }

    render(){
        let user = this.state.user
        let userStatus = this.state.userStatus?this.state.userStatus:{}
        let userStatuses = this.state.userStatuses?this.state.userStatuses:[]
        let newUserStatus = this.state.newUserStatus?this.state.newUserStatus:{}

        return (
            <Modal size="lg" centered={true}
                isOpen={this.state.modal}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    Update {label.UserStatus} : {user&&user.fullname}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="currentStatus">{label.CurrentStatus}</Label>
                                <Input
                                    style={{fontSize:'bold', color:statusColor(userStatus.id), borderColor: statusColor(userStatus.id)}}
                                    id="currentStatus"
                                    type="text"
                                    name="currentStatus"
                                    value={userStatus.name}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    title={label.SelectStatus}
                                    objects={userStatuses}
                                    default={newUserStatus.id}
                                    callback={(newUserStatus)=>{
                                        if(newUserStatus!=null){
                                            this.setState({
                                                newUserStatus:newUserStatus
                                            })
                                        }else{
                                            this.setState({
                                                newUserStatus:{}
                                            })
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <FormGroup>
                            <Label for="note">{label.Note}</Label>
                            <Input
                                id="note"
                                type="text"
                                name="note"
                                value={this.state.note}
                                onChange={(e) =>{
                                    let value = e.target.value
                                    this.setState({
                                        note : value?value:null
                                    })
                                }}
                                placeholder={label.EnterNoteRecommended}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        {
                            (newUserStatus.id && (newUserStatus.name!=user.userStatus))&&(
                                <Col>
                                    <Button color="primary" onClick={e=>{
                                        this.updateUserStatus()
                                    }}>
                                        {label.Update}
                                    </Button>
                                </Col>
                            )
                        }
                        <Col>
                            <Button color="secondary" onClick={e=>{
                                e.preventDefault()
                                this.setState({
                                    modal:false
                                })
                                if(this.state.closeCallback){
                                    this.state.closeCallback()
                                }
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

UpdateUserStatusModal.propTypes = {
    modal:PropTypes.bool,
    user:PropTypes.object,
    okCallback:PropTypes.func,
    closeCallback:PropTypes.func,
}
