import CurrencyFormatter from 'currencyformatter.js';
import cloneDeep from 'lodash/cloneDeep';
import {_DELIVERY, _DONE, _NOT_PASS_RETURN, _ON_RETURN, _ORDER, _PACKAGING, _PAID, _REJECTED} from './Global';
import {getAdmin} from './StorageUtil';
import label from '../message/Label'
//import dayjs from 'dayjs' // ES 2015
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

var dateFormat = require('dateformat');


export function cloneObject(object) {
    return cloneDeep(object)
}

export function parseDate(dateIn, format){
    if(!dateIn){
        return "-"
    }

    const date = new Date(dateIn)

    if(format){
        try {
            return dateFormat(date, format)
        }catch (e) {
            try{
             // alternatif invalid date
            dayjs.extend(customParseFormat)
            return dayjs(dateIn, "YYYY-MM-DDTHH:mm:ss.000ZZ").format("DD-MM-YYYY HH:mm:ss")
            }catch (e){
                return "-";
            }
        }
    }else{
        try {
            return dateFormat(date, "dd/mm/yyyy")
        }catch (e) {
            try{
                // alternatif invalid date
                dayjs.extend(customParseFormat)
                return dayjs(dateIn, "YYYY-MM-DDTHH:mm:ss.000ZZ").format("DD/MM/YYYY")
            }catch (e){
                return "-";
            }
        }
    }
}

export function translate(state){
    if(state){
        return label.Active;
    }else{
        return label.Inactive;
    }
}

export function sortirMap(key) {
    var sortRed = new Object();
    sortRed['name'] = label.Name
    sortRed['product.name'] = "Product"
    sortRed['product.category.name'] = label.Category
    sortRed['product.heading.name'] = "Heading"
    sortRed['product.brand.name'] = "Brand"
    sortRed['created'] = label.Created
    sortRed['quantity'] = "Quantity"
    sortRed['price'] = "Price"
    sortRed['updated'] = label.Updated
    sortRed['firstname'] = label.Name
    sortRed['role.name'] = label.Role
    sortRed['email'] = label.Email
    sortRed['c.firstname'] = label.Name
    sortRed['c.created'] = label.Created
    sortRed['c.email'] = label.Email
    sortRed['c.phoneNumber'] = label.Phone
    sortRed['c.updated'] = label.Updated
    sortRed['transactionCount'] = "Trx Count"
    sortRed['trx.created'] = "Trx Date"
    sortRed['refCode'] = label.ReferenceCode
    sortRed['item'] = "Item"
    sortRed['fs.created'] = label.Created
    sortRed['fs.updated'] = label.Updated
    sortRed['p.heading.name'] = "Heading"
    sortRed['p.category.name'] = label.Category
    sortRed['p.brand.name'] = "Brand"
    sortRed['p.name'] = label.Name

    sortRed['h.name'] = "Heading"
    sortRed['c.name'] = label.Category
    sortRed['b.name'] = "Brand"

    sortRed['stock'] = "Stock"
    sortRed['startDate'] = label.StartDate
    sortRed['ednDate'] = label.EndDate
    sortRed['s.name'] = label.Name
    sortRed['s.created'] = label.Created
    sortRed['s.updated'] = label.Updated
    sortRed['inventoryItem'] = "Inventory"
    sortRed['inventoryQuantity'] = "Quantity"
    sortRed['p.created'] = label.Created
    sortRed['p.updated'] = label.Updated
    sortRed['v.name'] = label.Name
    sortRed['v.created'] = label.Created
    sortRed['v.updated'] = label.Updated
    sortRed['v.startPeriod'] = label.StartPeriod
    sortRed['v.endPeriod'] = label.EndPeriod
    sortRed['v.start_period'] = label.StartPeriod
    sortRed['v.end_period'] = label.EndPeriod
    sortRed['typeName'] = label.Type
    sortRed['totalPay'] = "Total Pay"
    sortRed['trx.startCreated'] = "Trx Date"
    sortRed['transactionCount'] = "Trx Count"
    sortRed['favoriteCount'] = "Fav Count"
    sortRed['cartCount'] = "Cart Count"
    sortRed['cic.created'] = label.Created
    sortRed['cartCount'] = "Cart Count"
    sortRed['d.start_date'] = label.StartDate
    sortRed['d.end_date'] = label.EndDate

    sortRed['minimumQuantity'] = "Min Quantity"
    sortRed['availableStock'] = "Stock"

    sortRed['customer.firstname'] = label.Name
    sortRed['customer.email'] = label.Email
    sortRed['customer.phoneNumber'] = label.Phone

    sortRed['jjv.title'] = label.Title
    sortRed['jjv.fee'] = label.Fee
    sortRed['ja.itemFee'] = label.Fee
    sortRed['ja.updated'] = label.Updated
    sortRed['ja.created'] = label.Created
    sortRed['jt.title'] = label.Title
    sortRed['jt.created'] = label.Created
    sortRed['jt.suggestion'] = label.Suggestion
    sortRed['jt.inspectionCount'] = label.InspectionDone
    sortRed['jt.modelCategory.name'] = label.ModelCategory

    sortRed['jb.full_name'] = label.FullName
    sortRed['jb.website'] = label.Website
    sortRed['jb.model_count'] = label.ModelCount
    sortRed['jb.created'] = label.Created
    sortRed['jb.updated'] = label.Updated
    sortRed['p.fullName'] = label.Brand


    sortRed['u.created'] = label.Created
    sortRed['u.firstname'] = label.Name
    sortRed['u.email'] = label.Email
    sortRed['u.mobile_phone'] = label.MobilePhone
    sortRed['u.updated'] = label.Updated
    sortRed['u.total_income'] = "Total Income"

    sortRed['ud.created'] = label.Created
    sortRed['ud.updated'] = label.Updated
    sortRed['ud.userAgent'] = label.UserAgent

    sortRed['u.userCode'] = label.UserCode
    sortRed['u.mobilePhone'] = label.MobilePhone
    sortRed['u.email'] = label.Email
    sortRed['wr.amount'] = "Amount"
    sortRed['wr.created'] = label.Created
    sortRed['wr.updated'] = label.Updated

    sortRed['bm.created'] = label.Created
    sortRed['bm.updated'] = label.Updated
    sortRed['period'] = label.Period
    sortRed['af.productName'] = label.ProductName
    sortRed['af.percentFee'] = label.PercentFee
    sortRed['af.amountFee'] = label.AmountFee
    sortRed['af.agentFee'] = label.AgentFee
    sortRed['af.created'] = label.Created
    sortRed['af.updated'] = label.Updated

    sortRed['d.title'] = label.Title
    sortRed['d.seeCount'] = label.PreviewCount
    sortRed['d.shareCount'] = label.ShareCount
    sortRed['d.commentCount'] = label.CommentCount
    sortRed['d.created'] = label.Created
    sortRed['d.updated'] = label.Updated
    sortRed['ja.created'] = label.Created
    sortRed['ja.updated'] = label.Updated
    sortRed['ja.performerName'] = label.PerformedBy
    sortRed['ja.serialCode'] = label.SerialCode
    sortRed['ja.reference'] = label.Reference
    sortRed['jm.systemType'] = label.SystemType


    sortRed['jie.created'] = label.Created
    sortRed['jie.updated'] = label.Updated
    sortRed['jie.serialCode'] = label.SerialCode
    sortRed['jie.performerName'] = label.PerformedBy
    sortRed['ji.reference'] = label.Reference
    sortRed['jm.systemType'] = label.SystemType

    sortRed['documentNumber'] = label.DocumentNumber

    return sortRed[key]
}

export function statusColor(statusId){
    if(statusId===_ORDER){
        return "#3dc296"
    }

    if(statusId===_PAID){
        return "#5f86dd"
    }

    if(statusId===_PACKAGING){
        return "#ff8f0c"
    }

    if(statusId===_DELIVERY){
        return "#ff8f0c"
    }
    if(statusId===_DONE){
        return "#3dc296"
    }

    if(statusId===_REJECTED){
        return "#ff2f5f"
    }

    if(statusId===_ON_RETURN){
        return "#ff2f5f"
    }

    if(statusId===_NOT_PASS_RETURN){
        return "#ff2f5f"
    }


}

export function imageSelector(callback, mimeType, maxSize){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    if(mimeType){
        fileSelector.setAttribute('accept', mimeType);
    }else{
        fileSelector.setAttribute('accept', 'image/x-png, image/jpeg, image/x-icon');
    }
    // fileSelector.setAttribute('accept', 'image/x-png,image/gif,image/jpeg');
    // fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.onchange = function(event) {
        // alert(event.target.value)
        event.preventDefault()
        if (event.target.files && event.target.files[0]) {
            let files = event.target.files || event.dataTransfer.files;
            var fileSize = files[0].size / 1024 ;// in KB
            if(maxSize){
                if(fileSize>maxSize){
                    alert("File cannot be larger than "+maxSize+" Kb")
                    return ;
                }
            }
            var stnkfile = URL.createObjectURL(event.target.files[0])
            callback(stnkfile)
        }
    }
    return fileSelector;
}

export function fileSelector(callback, mimeType){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    if(mimeType){
        fileSelector.setAttribute('accept', mimeType);
    }
    fileSelector.onchange = function(event) {
        // alert(event.target.value)
        event.preventDefault()
        if (event.target.files && event.target.files[0]) {
            // let files = event.target.files || event.dataTransfer.files;
            // var file = URL.createObjectURL(event.target.files[0])
            var input = event.target
            var reader = new FileReader();
            reader.onload = function(){
                var text = reader.result;
                callback(input.files[0], text)
            };
            reader.readAsText(input.files[0]);
        }
    }
    return fileSelector;
}


export function toCurrency(amount) {
    return amount.toFixed(2);
};

export function isEmpty(input){
    // return (((input===undefined) || (input===null) || (input==='')
    //     || (typeof input=='object' && (Object.keys(input).length===0))) || (input.length==0)) && !(input instanceof Blob)

    if((input instanceof Blob)){
        return false
    }
    if(input===undefined || input===null || input==='' || input==='null' || input==='undefined'){
        return true
    }
    if(Object.prototype.toString.call(input) === '[object Date]' && input !== ''){
        return false
    }
    if((typeof input==='object' && Object.keys(input).length===0)){
        return true
    }
    if(input.length==0){
        return true
    }
    return false ;
}
export function isObject(input){
    if(input===undefined || input===null ){
        return false
    }
    if((typeof input==='object')){
        return true
    }
    return false
}

export function allIsEmpty(...inputs){
    var empty = (inputs===undefined) || (inputs===null) || (inputs==='')
    if(!empty){
        loop:
            for (let i = 0; i < inputs.length; i++) {
                var input = inputs[i]
                empty = isEmpty(input)
                if(empty){
                    break loop
                }
            }
    }
    return empty ;
}

export function isValidEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function percentToPrice(percent, price){
    if(isEmpty(percent) || percent===0 || isEmpty(price) || price===0){
        return 0 ;
    }
    return price*(percent/100)
}


export function finalPriceByPercent(percent, price){
    if(isEmpty(percent) || percent===0 || isEmpty(price) || price===0){
        return 0 ;
    }
    var finalPrice = price*(percent/100)
    return price - finalPrice
}

export function percentByDiscount(discount, price){
    if(isEmpty(discount) || discount===0 || isEmpty(price) || price===0){
        return 0 ;
    }
    return (discount*100)/price
}

export function navigatePage(props, currentPage) {
    let {pathname} = props.location
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', currentPage);
    props.history.push(pathname.includes("?")?(pathname+currentUrlParams.toString()):(pathname+"?"+currentUrlParams.toString()))
}

export function changeParam(props, param, value) {
    let {pathname} = props.location
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set(param, value);
    props.history.push(pathname.includes("?")?(pathname+currentUrlParams.toString()):(pathname+"?"+currentUrlParams.toString()))
}

export function changeParam2(props, param1, value1, param2, value2) {
    let {pathname} = props.location
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set(param1, value1);
    currentUrlParams.set(param2, value2);
    props.history.push(pathname.includes("?")?(pathname+currentUrlParams.toString()):(pathname+"?"+currentUrlParams.toString()))
}
export function changeParam3(props, param1, value1, param2, value2, param3, value3) {
    let {pathname} = props.location
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set(param1, value1);
    currentUrlParams.set(param2, value2);
    currentUrlParams.set(param3, value3);
    props.history.push(pathname.includes("?")?(pathname+currentUrlParams.toString()):(pathname+"?"+currentUrlParams.toString()))
}

export function navigateBack(props) {
    props.history.goBack()
}



export function deleteParam(props, param, ignoreNavigate) {
    let {pathname} = props.location
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.delete(param);
    if(!ignoreNavigate){
        props.history.push(pathname.includes("?")?(pathname+currentUrlParams.toString()):(pathname+"?"+currentUrlParams.toString()))
    }
}

export function decimalToFraction(decimal){
    decimal = decimal*100
    let divides = 1*100
    decimal = (decimal/5)/5
    divides = (divides/5)/5
    return [decimal, divides]
}

export function weightFormat(number) {
    let gram = true
    if(number>=1000){
        gram = false
        number = number/1000
    }
    return CurrencyFormatter.format(number, {
        symbol:'',
        decimal:'.',
        group:',',
        pattern:(gram?'#,##0.0 gram':'#,##0.0 kg')+' !'
    })
}


export function currencyFormat(price, symbol) {
    if(!price){
        price = 0
    }
    return CurrencyFormatter.format(price, {
            symbol:(symbol !=undefined)?(symbol+' '):'',
            decimal:',',
            group:'.',
            pattern:(symbol?symbol+' #,##0':'#,##0')
        }
    )
}


export function validatePrivilege(props){
    let admin = getAdmin()
    let currentPath = props.location.pathname
    let valid = false
    let i = 1
    // admin.role.roleMenus.map((item, index)=>{
    //     if(currentPath===item.menu.link){
    //         valid = true
    //     }
    //     i++
    //
    // })
    // if(!valid){
    //     navigateBack(props)
    // }


}


// Object.prototype.push = function( key, value ){
//     this[ key ] = value;
//     return this;
// }
// Object.prototype.remove = function( key){
//     delete this[ key ];
//     return this;
// }


export function hasBeenDelivery(statusId){
    if(statusId){
        if(statusId===_DELIVERY || statusId===_DONE || statusId === _ON_RETURN || statusId === _NOT_PASS_RETURN){
            return true ;
        }
    }
    return false ;
}

export function cleanStringify(object) {
    if (object && typeof object === 'object') {
        object = copyWithoutCircularReferences([object], object);
    }
    return JSON.stringify(object);

    function copyWithoutCircularReferences(references, object) {
        var cleanObject = {};
        Object.keys(object).forEach(function(key) {
            var value = object[key];
            if (value && typeof value === 'object') {
                if (references.indexOf(value) < 0) {
                    references.push(value);
                    cleanObject[key] = copyWithoutCircularReferences(references, value);
                    references.pop();
                } else {
                    cleanObject[key] = '###_Circular_###';
                }
            } else if (typeof value !== 'function') {
                cleanObject[key] = value;
            }
        });
        return cleanObject;
    }
}

export function slugBuilder(name){
    let slug = name?name:"";
    slug = slug.toLowerCase();
    slug = slug.trim();
    slug = slug.split(/[^a-zA-Z0-9- \\s]/).join("");
    slug = slug.trim()
    slug = slug.split(" ").join("-");
    return slug ;
}

export function isResponseOk(response) {
    return response.code === 200;
}

export function pageCount(rowSize, rowPerPage){
    let totalPage = 0 ;
    if(!rowSize || rowSize==0){
        totalPage = 0 ;
    }else{
        totalPage = ((rowSize / rowPerPage) + ((rowSize%rowPerPage)>0?1:0));
        totalPage = Math.ceil(totalPage)
    }
    return totalPage;
}
export function cleanPhoneNumber(input){
    return input?input.replace(/[^\d+]/g,''):""
}

export function canUseWebP() {
    var elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
}



// Adds a .toCurrency() method to Numbers.
Number.prototype.toCurrency = function() {
    return toCurrency(this);
}
