import React from 'react';
import {MdSearch} from 'react-icons/md';
import {Form, Input} from 'reactstrap';
import BaseComponent from './BaseComponent';
import {isEmpty} from '../utils/Utilities';
import PropTypes from 'prop-types';
import Tooltip from 'react-simple-tooltip';
import label from '../message/Label'

export default class SearchInput extends BaseComponent{

    constructor(props) {
        super(props);
        this.state = {
            placeholder:isEmpty(this.props.placeholder)?label.Searching+"...":this.props.placeholder
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props!=nextProps){
            this.setState({
                placeholder:isEmpty(nextProps.placeholder)?label.Searching+"...":nextProps.placeholder
            })
        }
    }

    handleKeyDown=(e)=>{
        if(this.props.onEnter){
            if (e.key === 'Enter') {
                e.preventDefault()
                this.props.onEnter(e)
            }
        }
    }

    render() {
        let value = this.props.value?this.props.value:""
        let tooltip = this.props.tooltip?this.props.tooltip:label.TypeAndEnterToSearch
        return (
            <>
                <Tooltip background="#ffffff" color="#000" padding={8} placement="bottom" fontSize="12px" content={tooltip} style={{width:'100%'}}>
                    <Form inline className="cr-search-form block" block>
                        <MdSearch
                            size="20"
                            className="cr-search-form__icon-search text-secondary"
                        />
                        <Input
                            type="search"
                            className="cr-search-form__input"
                            placeholder={this.state.placeholder}
                            value={value}
                            style={{width:'100%'}}
                            onChange={e=>{
                                e.preventDefault()
                                if(this.props.onChange){
                                    this.props.onChange(e)
                                }
                            }}
                            onKeyDown={this.handleKeyDown}
                        />
                    </Form>
                </Tooltip>
            </>

        );
    }
};

SearchInput.propTypes={
    tooltip: PropTypes.string,
    placeholder: PropTypes.string,
    onChange:PropTypes.func,
    onEnter:PropTypes.func,
}
