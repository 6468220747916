import React from 'react';
import BaseComponent from '../../BaseComponent';
import {_CLOSED, _NOT_PASS, _OPEN, _PASS, _SUSPENDED, _TAKEN} from '../../../utils/Global';
import {Button, Card, CardBody, CardHeader} from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import {Tabs, Tab} from '@material-ui/core';
import DashboardInspectionTable from './DashboardInspectionTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import {MdRefresh} from 'react-icons/md';
import label from '../../../message/Label'

let takenInspection = React.createRef()
let notPassInspectionRef = React.createRef()
let passInspectionRef = React.createRef()
let closedInspectionRef = React.createRef()
let openedInspectionRef = React.createRef()
let suspendedInspectionRef = React.createRef()

export default class DashboardInspectionCard extends BaseComponent{

    constructor(props) {
        super(props);
        let state = this.state
        state.inspectionStatusId = _TAKEN
        state.fecthing = false
        this.setState(state)
    }

    updateAnalytic=()=>{
        let inspectionStatusId = this.state.inspectionStatusId
        if(parseInt(inspectionStatusId)===_TAKEN && this.takenInspection){
            this.takenInspection.fetchTransaction(true)
        }
        if(parseInt(inspectionStatusId)===_NOT_PASS && this.notPassInspectionRef){
            this.notPassInspectionRef.fetchTransaction(true)
        }
        if(parseInt(inspectionStatusId)===_PASS && this.passInspectionRef){
            this.passInspectionRef.fetchTransaction(true)
        }
        if(parseInt(inspectionStatusId)===_CLOSED && this.closedInspectionRef){
            this.closedInspectionRef.fetchTransaction(true)
        }
        if(parseInt(inspectionStatusId)===_OPEN && this.openedInspectionRef){
            this.openedInspectionRef.fetchTransaction(true)
        }
        if(parseInt(inspectionStatusId)===_SUSPENDED && this.suspendedInspectionRef){
            this.suspendedInspectionRef.fetchTransaction(true)
        }
    }

    badgeTabLabel=(label, size, color)=>{
        if(!size || size==0){
            return (<span>{label}</span>)
        }
        return (
            <>
                <span>{label}</span>
                &nbsp;&nbsp;
                <span
                    style={{
                        paddingTop:'2px',
                        paddingBottom:'2px',
                        paddingLeft:'6px',
                        paddingRight:'6px',
                        background:color?color:'red',
                        color:'white',
                        borderRadius:'20%',
                        minWidth:'24px',
                        display:'inline-block',
                        textAlign:'center'}}>
                    {size}
                </span>
            </>)
    }


    loading=(fecthing)=>{
        this.setState({
            fecthing : fecthing
        })
    }


    render() {
        let inspectionStatusId = this.state.inspectionStatusId
        let fetching = this.state.fetching
        return (
            <Card>
                <CardHeader>
                    {label.OnGoingInspection}
                    {
                        (fetching)?(
                            <div className="float-right" >
                                <CircularProgress color="secondary" size={24}/>
                            </div>
                        ):(
                            <div className="float-right" >
                                <Button className="float-right" onClick={event=>{
                                    this.updateAnalytic()
                                }}><MdRefresh/></Button>
                            </div>
                        )
                    }
                </CardHeader>
                <CardBody>
                    <Tabs
                        activeKey={inspectionStatusId}
                        onSelect={key =>{
                            this.setState({
                                inspectionStatusId:key
                            })
                        }}>
                        <Tab eventKey={_TAKEN}
                             title={this.badgeTabLabel(label.Taken, this.takenInspection?this.takenInspection.totalElement():0, '#f3b519')}>
                            <DashboardInspectionTable
                                ref={(ref)=>this.takenInspection=ref} {...this.props}
                                show={parseInt(inspectionStatusId)==_TAKEN}
                                inspectionStatusId={_TAKEN}
                                fetching={(fetching)=>{
                                this.setState({fetching:fetching})
                            }}/>
                        </Tab>
                        <Tab eventKey={_PASS}
                             title={this.badgeTabLabel(label.Pass, this.passInspectionRef?this.passInspectionRef.totalElement():0, '#6983FB')}>
                            <DashboardInspectionTable
                                ref={(ref)=>this.passInspectionRef=ref}
                                {...this.props}
                                show={parseInt(inspectionStatusId)==_PASS}
                                inspectionStatusId={_PASS} fetching={(fetching)=>{
                                this.setState({fetching:fetching})
                            }}/>
                        </Tab>
                        <Tab eventKey={_NOT_PASS}
                             title={this.badgeTabLabel(label.NotPass, this.notPassInspectionRef?this.notPassInspectionRef.totalElement():0, '#FF9803')}>
                            <DashboardInspectionTable
                                ref={(ref)=>this.notPassInspectionRef=ref}
                                {...this.props}
                                show={parseInt(inspectionStatusId)==_NOT_PASS}
                                inspectionStatusId={_NOT_PASS} fetching={(fetching)=>{
                                this.setState({fetching:fetching})
                            }}/>
                        </Tab>

                        <Tab eventKey={_CLOSED}
                             title={this.badgeTabLabel(label.Closed, this.closedInspectionRef?this.closedInspectionRef.totalElement():0, '#0d6c10')}>
                            <DashboardInspectionTable
                                ref={(ref)=>this.closedInspectionRef=ref} {...this.props} show={parseInt(inspectionStatusId)==_CLOSED} inspectionStatusId={_CLOSED} fetching={(fetching)=>{
                                this.setState({fetching:fetching})
                            }}/>
                        </Tab>
                        <Tab eventKey={_OPEN}
                             title={this.badgeTabLabel(label.Opened, this.openedInspectionRef?this.openedInspectionRef.totalElement():0, '#108989')}>
                            <DashboardInspectionTable
                                ref={(ref)=>this.openedInspectionRef=ref}
                                {...this.props} show={parseInt(inspectionStatusId)==_OPEN} inspectionStatusId={_OPEN} fetching={(fetching)=>{
                                this.setState({fetching:fetching})
                            }}/>
                        </Tab>
                        <Tab eventKey={_SUSPENDED}
                             title={this.badgeTabLabel(label.Suspended, this.suspendedInspectionRef?this.suspendedInspectionRef.totalElement():0, '#FF0839')}>
                            <DashboardInspectionTable
                                ref={(ref)=>this.suspendedInspectionRef=ref} {...this.props} show={parseInt(inspectionStatusId)==_SUSPENDED} inspectionStatusId={_SUSPENDED} fetching={(fetching)=>{
                                this.setState({fetching:fetching})
                            }}/>
                        </Tab>
                    </Tabs>
                </CardBody>
            </Card>
        );
    }

}
