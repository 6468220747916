import React from 'react';
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import ItemOption from '../Widget/ItemOption';
import Global, {ADMIN_STATUSES} from '../../utils/Global';
import BaseComponent from '../BaseComponent';
import {statusColor} from '../../utils/Utilities';
import label from '../../message/Label'
/*= (message, okCallback, cancelCallback) =>*/
export default class UpdateAdminStatusModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        state.modal= this.props.modal
        state.backdrop= false
        state.admin = this.props.admin
        state.adminStatus = {}
        state.adminStatuses = ADMIN_STATUSES
        state.newAdminStatus = {}
        state.okCallback = this.props.okCallback
        state.closeCallback = this.props.closeCallback
        state.note = ""
        state.resiCodeMessage=""
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        let modal = this.state.modal
        if(this.state.modal!=props.modal && props.modal){
            let admin = props.admin
            let adminStatus = null
            ADMIN_STATUSES.forEach(value => {
                if(value.name===admin.adminStatus){
                    adminStatus = value
                }
            })
            this.setState({
                modal:props.modal,
                admin:admin,
                okCallback : props.okCallback,
                closeCallback : props.closeCallback,
                adminStatus:adminStatus,
                newAdminStatus:{},
                note:"",
                resiCodeMessage : "",
            })
        }
    }


    updateAdminStatus=()=>{
        let adminStatus = this.state.adminStatus?this.state.adminStatus:{}
        let newAdminStatus = this.state.newAdminStatus?this.state.newAdminStatus:{}
        let note = this.state.note?this.state.note:""
        let admin = this.state.admin
        let confirmationMessage = label.DoYouWantToUpdateThisAdminStatusFrom(adminStatus.name, newAdminStatus.name)
        this.openConfirmDialog(label.Confirmation, confirmationMessage, ()=>{
            let formData = new FormData();
            formData.append("adminId", admin.id)
            formData.append("status", newAdminStatus.name)
            formData.append("reason", note)
            this.post(Global.API.ADMIN_UPDATE_STATUS, formData, null, response=>{
                if(response.code===200){
                    let verification = response.data
                    this.successToast(response.message)
                    this.setState({
                        modal:false
                    }, () => {
                        if(this.state.okCallback){
                            this.state.okCallback(verification)
                        }
                    })
                }
            }, true, true)
        })
    }

    render(){
        let admin = this.state.admin
        let adminStatus = this.state.adminStatus?this.state.adminStatus:{}
        let adminStatuses = this.state.adminStatuses?this.state.adminStatuses:[]
        let newAdminStatus = this.state.newAdminStatus?this.state.newAdminStatus:{}

        return (
            <Modal size="lg" centered={true}
                isOpen={this.state.modal}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    Update {label.AdminStatus} : {admin&&admin.fullname}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="currentStatus">{label.CurrentStatus}</Label>
                                <Input
                                    style={{fontSize:'bold', color:statusColor(adminStatus.id), borderColor: statusColor(adminStatus.id)}}
                                    id="currentStatus"
                                    type="text"
                                    name="currentStatus"
                                    value={adminStatus.name}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    title={label.SelectStatus}
                                    objects={adminStatuses}
                                    default={newAdminStatus.id}
                                    callback={(newAdminStatus)=>{
                                        if(newAdminStatus!=null){
                                            this.setState({
                                                newAdminStatus:newAdminStatus
                                            })
                                        }else{
                                            this.setState({
                                                newAdminStatus:{}
                                            })
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <FormGroup>
                            <Label for="note">{label.Note}</Label>
                            <Input
                                id="note"
                                type="text"
                                name="note"
                                value={this.state.note}
                                onChange={(e) =>{
                                    let value = e.target.value
                                    this.setState({
                                        note : value?value:null
                                    })
                                }}
                                placeholder={label.EnterNoteRecommended}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        {
                            (newAdminStatus.id && (newAdminStatus.name!=admin.adminStatus))&&(
                                <Col>
                                    <Button color="primary" onClick={e=>{
                                        this.updateAdminStatus()
                                    }}>
                                        {label.Update}
                                    </Button>
                                </Col>
                            )
                        }
                        <Col>
                            <Button color="secondary" onClick={e=>{
                                e.preventDefault()
                                this.setState({
                                    modal:false
                                })
                                if(this.state.closeCallback){
                                    this.state.closeCallback()
                                }
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

UpdateAdminStatusModal.propTypes = {
    modal:PropTypes.bool,
    admin:PropTypes.object,
    okCallback:PropTypes.func,
    closeCallback:PropTypes.func,
}
