import React from 'react';
import PropTypes from 'prop-types';

export default class HorizontalLine extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            color : this.props.color,
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props!=nextProps){
            this.setState({
                color : nextProps.color,
            })
        }
    }

    render() {
        let color = this.state.color?this.state.color:"#fff"
        return (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    height: 1
                }}
            />
        );
    }
}

HorizontalLine.propTypes = {
    color:PropTypes.string,

}
