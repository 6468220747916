import React from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ValidatorLabel from '../Widget/ValidatorLabel';
import PropTypes from 'prop-types';
import Global from '../../utils/Global';
import BaseComponent, { toast } from '../BaseComponent';
import { saveAdminSession } from '../../utils/StorageUtil';
import label from '../../message/Label'

/*= (message, okCallback, cancelCallback) =>*/

export default class TestSendMailDialog extends BaseComponent {

    constructor(props) {
        super(props);
        let state = this.state
        state.modal = this.props.modal
        state.email = null
        state.emailError = null
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if (this.props != props) {
            this.setState({
                modal: props.modal,
                email: null,

            })
        }
    }

    sendMail = () => {
        let state = this.state


        let email = state.email

        if (!email || email === '') {
            state.emailError = label.PleaseEnterEmail
            this.setState(state)
            return
        }

        let params = {
            email: email
        }

     
            this.get(Global.API.CONFIGURATION_ADMIN_TEST_SEND_MAIL, {params:params}, null, response =>{
                if(response.code===200){
                    toast.success(response.message)
                    this.setState({
                        modal:false
                    }, () => {
                        if(this.props.okCallback!=null){
                            this.props.okCallback()
                        }
                    })
                }

        }, true, true);
        
       
    }


    render() {
        return (
            <Modal
                isOpen={this.state.modal}
                centered={true}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    {label.TestSendMail}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    value={(this.state.email != null && this.state.email) ? this.state.email : ""}
                                    onChange={(e) => {
                                        this.setState({
                                            email: e.target.value
                                        })
                                    }}
                                    placeholder={label.EnterYourMail}
                                />
                                <ValidatorLabel message={this.state.emailError} />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary" onClick={e => {
                            e.preventDefault()
                            this.setState({
                                modal: false
                            }, () => {
                                if (this.props.cancelCallback != null) {
                                    this.props.cancelCallback()
                                }
                            })
                        }}>
                        {label.Cancel}
                    </Button>
                    &nbsp;
                    <Button
                        color="warning" onClick={e => {
                            e.preventDefault()
                            this.sendMail()
                        }}>
                        {label.SendEmail}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

TestSendMailDialog.propTypes = {
    modal: PropTypes.bool.isRequired,
    okCallback: PropTypes.func,
    cancelCallback: PropTypes.func,
}
