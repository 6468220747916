import * as React from 'react';
import BaseComponent from '../BaseComponent';
import {Button, Card, CardBody, Col, Modal, Row} from "reactstrap";
import * as PropTypes from 'prop-types';
import label from "../../message/Label"
import inspectionImage from 'assets/img/banner/inspection.png'
import HorizontalLine from "../Widget/HorizontalLine";
import MyCardImage from "../Card/MyCardImage";

/*= (message, okCallback, cancelCallback) =>*/
export default class ReopenInspectionConfirmModal extends BaseComponent{

    constructor(props){
        super(props);
        this.state = {
            inspection:this.props.inspection?this.props.inspection:{}
        }
    }
    componentWillReceiveProps(props, nextContext) {
        if(this.props!==props){
            this.setState({
                inspection:props.inspection?props.inspection:{}
            })
        }
    }


    render(){
        let {inspection} = this.state
        let model = inspection.model?inspection.model:{}
        return (
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    size="md"
                    centered={true}
                    isOpen={this.props.show}
                    backdrop={true}>
                    <Card style={{textAlign:'center'}}>
                        <CardBody>
                            <Row>
                                <Col>
                                    <MyCardImage style={{padding:'32px'}} style={{width:'80%'}} src={inspectionImage}/>
                                </Col>
                            </Row>
                            <HorizontalLine/>
                            <Row>
                                <Col>
                                    <h3>
                                        <strong>
                                            {model.systemType}
                                        </strong>
                                    </h3>
                                    <br/>
                                    <h5>{label.Reference}</h5>
                                    <br/>
                                    <h3>
                                        <strong>
                                            {inspection.reference}
                                        </strong>
                                    </h3>
                                    <br/>
                                    <strong style={{color:'brown'}}>
                                        {label.DoYouWantToReopenThisInspection_ThisActionWillCauseTheInspectionCouldBeTakenByAnyUser}
                                    </strong>
                                </Col>
                            </Row>
                            <HorizontalLine/>
                            <Row>
                                <Col style={{textAlign:'left'}}>
                                    <Button
                                        block
                                        color={"danger"}
                                        onClick={() => {
                                            this.props.onClickCancel()
                                        }}>
                                        {label.Cancel}
                                    </Button>
                                </Col>
                                <Col style={{textAlign:'right'}}>
                                    <Button
                                        block
                                        onClick={() => {
                                            this.props.onClickOk()
                                        }}>
                                        {label.ContinueReOpenInspection}
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Modal>
            </>
           )
    }
}

ReopenInspectionConfirmModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClickOk:PropTypes.func.isRequired,
    onClickCancel:PropTypes.func.isRequired,
    inspection:PropTypes.object.isRequired,
}
