import Page from 'components/Page';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import shoppingBag from 'assets/img/products/shopping-bag.png';
import CardBlock from 'reactstrap/es/CardBlock';
import BasePage from './BasePage';
import Global, {CATEGORY_IMAGE_MAX_FILE_SIZE, DD_MM_YYYY_HH_MM_SS, MIME_PNG_JPEG} from '../utils/Global';
import {allIsEmpty, cloneObject, imageSelector, isEmpty, parseDate, sortirMap} from '../utils/Utilities';
import {MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveOption from '../components/Widget/ActiveOption';
import ActiveLabel from '../components/Widget/ActiveLabel';
import WarningLabel from '../components/Widget/WarningLabel';
import axios from 'axios';
import ImageCropper from '../components/modal/ImageCropper';
import label from '../message/Label'
import MyCardImage from "../components/Card/MyCardImage";
import Image from "../components/Widget/Image";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class ModelCategoryPage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      modelCategories : [],
      modelCategory:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      image : undefined,
      modal: false,
      ascending:true,
      sortir:'created',
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  confirmSave = () =>{
    var modelCategory = this.state.modelCategory
    var image = this.state.imageBlob
    if(modelCategory.id){
      if(allIsEmpty(modelCategory.name)){
        this.showDialog(label.Warning, label.PleaseCompleteTheFields)
      }else{
        this.setState({
          modalAddConfirm:true
        })
      }
    }else{
      if(allIsEmpty(image, modelCategory.name)){
        this.showDialog(label.Warning, label.PleaseCompleteTheFields)
      }else{
        this.setState({
          modalAddConfirm:true
        })
      }
    }
  }


  save = () => {
    let modelCategory = this.state.modelCategory
    let imageBlob = this.state.imageBlob
    var formData = new FormData();

    if(!modelCategory.id && !modelCategory.active){
      modelCategory.active = false ;
    }

    formData.append("modelCategoryJson", JSON.stringify(modelCategory))
    formData.append("multipartFile", imageBlob);
    this.setState({
      modalAddConfirm:false
    }, () => {
      this.post(Global.API.MODEL_CATEGORY_SAVE, formData, null, res=>{
        if(res.code===200){
          this.setState({
            modelCategory:{},
            image:undefined
          }, () => {
            this.showDialog(label.Success, res.message)
            this.fetchAll()
          })
        }
      },  true, true)
    })
  }


  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Global.API.MODEL_CATEGORY_DELETE+"/"+this.state.modelCategory.id, null, null, res => {
        if(res.code===200){
          this.setState({
            modelCategory:{}
          }, () => {
            this.fetchAll()
          })
        }
      },true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Global.API.MODEL_CATEGORIES, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          modelCategories : response.data
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )


  render() {
    let aspect = 4/4
    let {image} = this.state

    return (
        <Page
            title={label.ModelCategory}
            breadcrumbs={[{ name: label.ModelCategory, active: true }]}
            className="TablePage">
          {super.render()}
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message={label.UpdateConfirmation}
              okCallback={this.save}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message={label.DeleteConfirmationMessage}
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card>
                <CardHeader>{label.ModelCategoryDetail}</CardHeader>
                <CardBody >
                  <Row>
                    <Col md={3}>
                      <Card body>
                        <Row>
                          <Col>
                            <Card>
                              <MyCardImage
                                  top
                                  src={image?image:shoppingBag}
                                  onError={(elm)=>this.defaultImage(elm, shoppingBag)}
                                  onClick={e=>{
                                    if(image){
                                      this.openLightBoxSingleImage(image)
                                    }
                                  }}
                                  onError={(elm)=>this.defaultImage(elm, shoppingBag)}/>
                              <CardBody>
                                <CardTitle>{label.CategoryImage}</CardTitle>
                                <Row>
                                  <Col>
                                    <WarningLabel message={"*Use Jpeg Format / Max "+CATEGORY_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                  </Col>
                                </Row>
                                <br/>
                                <Row>
                                  <Col>
                                    <ButtonGroup className="float-left">
                                      <Button color="danger" onClick={e => (
                                          this.setState({
                                            product:undefined,
                                            image:undefined
                                          })
                                      )}>{label.Cancel}</Button>
                                    </ButtonGroup>
                                  </Col>
                                  <Col>
                                    <ButtonGroup className="float-right">
                                      <Button color="primary" onClick={e=>{
                                        imageSelector(file=>{
                                          this.setState({
                                            image:file,
                                            cropperModal:true
                                          })
                                        }, MIME_PNG_JPEG, CATEGORY_IMAGE_MAX_FILE_SIZE).click()
                                      }}>Upload</Button>
                                    </ButtonGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <CardBlock>
                            <FormGroup>
                              <Label for="name">{label.Name}</Label>
                              <Input
                                  type="text"
                                  name="name"
                                  value={!isEmpty(this.state.modelCategory.name)?this.state.modelCategory.name:""}
                                  onChange={(e) =>{
                                    let ctg = this.state.modelCategory
                                    if(ctg!=null){
                                      ctg.name = e.target.value
                                      this.setState({
                                        modelCategory:ctg
                                      })
                                    }
                                  }}
                                 placeholder={label.EnterModelCategoryName}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="name">{label.ModelCount}</Label>
                              <Input
                                  type="text"
                                  name="productItem"
                                  value={(this.state.modelCategory)?this.state.modelCategory.totalModel:""}
                                  readOnly
                              />
                            </FormGroup>
                            <FormGroup>
                              <ActiveOption
                                default={this.state.modelCategory.active}
                                callback={(active)=>{
                                  let ctg = this.state.modelCategory
                                  if(ctg!=null){
                                    ctg.active = active
                                    this.setState({
                                      modelCategory:ctg
                                    })
                                  }

                                }}
                            />
                            </FormGroup>
                        </CardBlock>
                        </Row>
                        <Row>
                          <Col>
                            <ButtonGroup className="float-left">
                              <Button color="danger" onClick={e => (
                                  this.setState({
                                    modelCategory:{},
                                    image:undefined
                                  })
                              )}>{label.Cancel}</Button>
                            </ButtonGroup>
                          </Col>
                          <Col>
                            <ButtonGroup className="float-right">
                              <Button color="primary" onClick={e=>(
                                  this.confirmSave()
                              )}> {
                                (this.state.modelCategory!=null && this.state.modelCategory.id!=null)?label.Update:label.Add
                              } </Button>

                            </ButtonGroup>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col md={9}>
                      <Card body>
                        <Row>
                          <Col>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  sortirMap(this.state.sortir)
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  this.state.ascending?label.Ascending:label.Descending
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table responsive hover>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>{label.Name}</th>
                                <th>{label.ModelCount}</th>
                                <th style={{width:'10%'}}>{label.Image}</th>
                                <th>{label.Status}</th>
                                <th>{label.Updated}</th>
                                <th>{label.Edit}</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.modelCategories.map((item, index) =>(
                                    <tr key={index}>
                                      <th scope="row">{++index}</th>
                                      <td>{item.name}</td>
                                      <td>{item.totalModel}</td>
                                      <td>
                                        {
                                          item.icon&&(
                                              <Image src={item.iconUrl} style={{width:"50%"}}/>
                                          )
                                        }
                                      </td>
                                      <td><ActiveLabel trueLabel={label.Active} falseLabel={label.Inactive} active={item.active}/></td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>
                                        <Button color="primary" onClick={e=> {
                                          let modelCategory = cloneObject(item)
                                          image = modelCategory.icon?modelCategory.iconUrl:undefined
                                          this.setState({
                                            modelCategory: modelCategory,
                                            image: image
                                          })
                                        }}>
                                          <MdEdit/>
                                        </Button>
                                      </td>
                                    </tr>
                                ))
                              }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ImageCropper src={this.state.image} aspect={aspect} show={this.state.cropperModal} callback={file=>{
            axios({
              url: file,
              method: 'GET',
              responseType: 'blob', // important
            }).then((response) => {
              var fileBlob = response.data ;
              this.setState({
                image:file,
                cropperModal:false,
                imageBlob:fileBlob,
              })
            })
          }} cancelCallback={()=>{
            this.setState({
              cropperModal:false,
              image:this.state.modelCategory.imageLink,
              imageBlob:null,
            })
          }}/>

        </Page>
    );
  }
}

export default Object.assign(ModelCategoryPage, {ENDPOINT : "/categories"})
