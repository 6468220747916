import BasePage from './BasePage';
import React from 'react';
import queryString from 'query-string';
import Page from '../components/Page';
import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Collapse, FormGroup, Input, Label, Row} from 'reactstrap';
import {isEmpty, parseDate} from '../utils/Utilities';
import Global, {
    _POSTPONED,
    _CLOSED,
    _OPEN,
    _SUSPENDED,
    _TAKEN,
    DD_MM_YYYY_HH_MM_SS,
    INSPECTION_STATUS_COLOR,
} from '../utils/Global';
import userProfilePicture from 'assets/img/users/user_pp.png';
import {CgDanger, FiBookOpen, MdDetails, MdHistory, MdPrint} from 'react-icons/all';
import SockJsClient from 'react-stomp';
import label from '../message/Label'
import InspectionEventRectifierControllerSerialNumber
    from "../components/Widget/InspectionEventRectifierControllerSerialNumber";
import InspectionEventFormLaneWidget from "../components/Widget/InspectionEventFormLaneWidget";
import {FiArrowDownCircle, FiArrowUpCircle} from "react-icons/fi";
import Typography from "../components/Typography";
import InspectionEventHistoryModal from "../components/modal/InspectionEventHistoryModal";
import ModelModal from "../components/modal/ModelModal";
import {getAdmin, getData} from "../utils/StorageUtil";
import MyCardImage from "../components/Card/MyCardImage";
import ConfirmInspectionSuspendModal from "../components/modal/ConfirmInspectionSuspendModal";
import ReopenInspectionConfirmModal from "../components/modal/ReopenInspectionConfirmModal";
import {inspectionEventsFromNative} from "../utils/MyUtilities";

class InspectionEventDetailPage extends BasePage{

    constructor(props) {
        super(props);
        let inspectionEventIdQuery = queryString.parse(this.props.query).id
        let state = this.state
        state.inspectionEventIdQuery = inspectionEventIdQuery
        state.inspection = {}
        state.inspectionEvent = {}
        state.inspectionEvents = []
        state.user = {}
        state.updateStatusModal = false
        state.voucherInfoModal = false
        state.stepNumber = 0
        state.userFilterExpand = true
        state.confirmInspectionCloseModal = false
        state.confirmInspectionSuspendModal = false
        state.inspectionEventHistoryModal = false
        state.reopenInspectionConfirmModal = false ;
        state.modelModal = false
        if(isEmpty(inspectionEventIdQuery)){
            this.props.history.goBack();
        }
        this.setState(state)
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchInspectionEventDetail()
        setTimeout(()=>{
            this.setState({
                userFilterExpand:false
            })
        }, 3000)
    }

    fetchInspectionEventDetail =() =>{
        this.get(Global.API.INSPECTION_EVENT, {
            params :{
                id : this.state.inspectionEventIdQuery
            }
        }, null, response =>{
            if(response.code===200){
                let inspectionEvent = response.data
                let inspection = inspectionEvent.inspection
                this.setState({
                    inspectionEvent : inspectionEvent,
                    inspection : inspection
                }, () => {
                    this.fetchInspectionEvents(inspection.reference)
                })
            }
        }, true, true)
    }

    fetchInspectionEvents = (reference) =>{
        this.get(Global.API.INSPECTION_EVENTS, {
            params :{
                reference : reference,
                rowLimit:2,
                // list:true
            }
        }, null, response =>{
            if(response.code===200){
                // let inspectionEvents = response.data
                let inspectionEvents = inspectionEventsFromNative(response.data)
                this.setState({
                    inspectionEvents : inspectionEvents,
                })
            }
        }, true, false)
    }

    nextPage = () => {
        let {stepNumber, inspection, inspectionEvents,inspectionEvent,
            nextLabel, previousLabel, modeRectifierControllerSerialNumbers} = this.state
        // let currentInspectionEvent = inspection.currentInspectionEvent?inspection.currentInspectionEvent:{}
        // let currentInspectionEvent = inspectionEvents.length>=1?inspectionEvents[0]:{}
        let currentInspectionEvent = inspectionEvent
        let formLaneApplications = currentInspectionEvent.formLaneApplications?currentInspectionEvent.formLaneApplications:[]
        if(modeRectifierControllerSerialNumbers){
            // currentInspectionEvent.inspectionStatus = {}
            this.setState({
                confirmInspectionModal:true,
                currentInspectionEvent:currentInspectionEvent
            })
            return;
        }

        let formLaneApplication = formLaneApplications[stepNumber];
        let formApplications = formLaneApplication.formApplications?formLaneApplication.formApplications:[]
        if(stepNumber>=formLaneApplications.length-1){
            this.setState({
                nextLabel : label.ConfirmSubmission,
                previousLabel : label.Previous,
                modeRectifierControllerSerialNumbers:true
            })
            return
        }
        stepNumber = stepNumber+1;
        if(stepNumber>0){
            nextLabel = label.Next
            previousLabel = label.Previous
        }else{
            nextLabel = label.Next
            previousLabel = label.Cancel
        }
        this.setState({
            stepNumber : stepNumber,
            nextLabel:nextLabel,
            previousLabel:previousLabel
        })
    }

    previousPage = () => {
        let {stepNumber, nextLabel, previousLabel, modeRectifierControllerSerialNumbers} = this.state
        if(modeRectifierControllerSerialNumbers){
            if(stepNumber>0){
                nextLabel = label.Next
                previousLabel = label.Previous
            }else{
                nextLabel = label.Next
                previousLabel = label.Cancel
            }
            this.setState({
                modeRectifierControllerSerialNumbers:false,
                stepNumber : stepNumber,
                nextLabel:nextLabel,
                previousLabel:previousLabel
            })
            return;
        }
        if(stepNumber<=0){
            return
        }
        stepNumber = stepNumber-1;
        if(stepNumber>0){
            nextLabel = label.Next
            previousLabel = label.Previous
        }else{
            nextLabel = label.Next
            previousLabel = label.Cancel
        }
        this.setState({
            modeRectifierControllerSerialNumbers:false,
            stepNumber : stepNumber,
            nextLabel:nextLabel,
            previousLabel:previousLabel
        })
    }

    suspendInspection=()=>{
        let {inspection, inspectionEvents} = this.state
        // let currentInspectionEvent = inspection.currentInspectionEvent?inspection.currentInspectionEvent:{}
        let currentInspectionEvent = inspectionEvents.length>=1?inspectionEvents[0]:{}
        this.get(Global.API.INSPECTION_SUSPEND+"/"+_SUSPENDED, {params:{inspectionId:inspection.id}}, null, (response)=>{
            if(response.code===200){
                this.openSuccessDialog(label.Success, label.SuccessUpdatingStatus, ()=>{
                    this.props.history.goBack()
                })
            }else{
                this.openFailedDialog(label.FailedUpdatingStatus, response.message, ()=>{})
            }
        }, true, false)
    }

    reopenInspection=()=>{
        let {inspection, inspectionEvents} = this.state
        // let currentInspectionEvent = inspection.currentInspectionEvent?inspection.currentInspectionEvent:{}
        let currentInspectionEvent = inspectionEvents.length>=1?inspectionEvents[0]:{}

        this.get(Global.API.INSPECTION_REOPEN+"/"+_OPEN, {params:{inspectionId:inspection.id}}, null, (response)=>{
            if(response.code===200){
                this.openSuccessDialog(label.Success, label.SuccessUpdatingStatus, ()=>{
                    this.props.history.goBack()
                })
            }else{
                this.openFailedDialog(label.FailedUpdatingStatus, response.message, ()=>{})
            }
        }, true, false)
    }



    render() {

        let {confirmInspectionSuspendModal, reopenInspectionConfirmModal,
            inspectionEventHistoryModal, modelModal, inspection, inspectionEvent, inspectionEvents} = this.state


        let admin = getAdmin()
        let {modeRectifierControllerSerialNumbers, stepNumber} = this.state
        let inspectionStatus = inspectionEvent.inspectionStatus?inspectionEvent.inspectionStatus:{}

        let currentInspectionEvent = inspectionEvents.length>=1?inspectionEvents[0]:{}

        let previousInspectionEvent = inspectionEvents.length>=2?inspectionEvents[1]:{}
        if(!previousInspectionEvent.id){
            previousInspectionEvent = inspectionEvents.length>=1?inspectionEvents[0]:{}
        }
        let previousInspectionStatus = previousInspectionEvent.inspectionStatus?previousInspectionEvent.inspectionStatus:{}
        let previousInspectionUser = previousInspectionEvent.user?previousInspectionEvent.user:{}

        let performer= {
            "name":inspectionEvent.performerName,
            "role":inspectionEvent.performerRole,
            "imageUrl":inspectionEvent.performerImageUrl
        }

        // let user = isEmpty(currentInspectionUser.id)?previousInspectionUser:currentInspectionUser

        let formLaneApplications = inspectionEvent.formLaneApplications?inspectionEvent.formLaneApplications:[]

        let model = inspection&&inspection.model?inspection.model:{}
        let authorization = getData(Global.AUTHORIZATION)

        let arrInspectionEvent = inspectionEvents && inspectionEvents && Object.keys(inspectionEvents).length > 0 ? inspectionEvents : []

        let lastPerformerAndStatus = null;
        let previousPerformerAndStatus = null;

        let historyInspectionEvent = arrInspectionEvent.reduce(function (arr, current) {
            if (typeof (arr) == "undefined") arr = []
           
            // const exist = arr.find(it => it.user.id === current.user.id);
            const exist = arr.find(it => {
                if(it && it.user && current && current.user){
                  return it.user.id === current.user.id
                }else if(it && it.admin && current && current.admin){
                  return it.admin.id === current.admin.id
                }
            });

            if (!exist) {
                arr.push(current);
            }
            return arr;
        }, [])


        if (historyInspectionEvent && Object.keys(historyInspectionEvent).length > 0) {
            lastPerformerAndStatus = {
                "name": historyInspectionEvent[0] && historyInspectionEvent[0].performerName ? historyInspectionEvent[0].performerName : historyInspectionEvent[0].user && historyInspectionEvent[0].user.fullname ? historyInspectionEvent[0].user.fullname : "",
                "role": historyInspectionEvent[0] && historyInspectionEvent[0].performerRole ? historyInspectionEvent[0].performerRole : "",
                "status": historyInspectionEvent[0] && historyInspectionEvent[0].inspectionStatus && historyInspectionEvent[0].inspectionStatus.name ? historyInspectionEvent[0].inspectionStatus.name : "ALL",
                "label": historyInspectionEvent[0] && historyInspectionEvent[0].inspectionStatus && historyInspectionEvent[0].inspectionStatus.label ? historyInspectionEvent[0].inspectionStatus.label : ""
            }

            if (historyInspectionEvent && Object.keys(historyInspectionEvent).length > 1) {
                previousPerformerAndStatus = {
                    "name": historyInspectionEvent[1] && historyInspectionEvent[1].performerName ? historyInspectionEvent[1].performerName : historyInspectionEvent[1].user && historyInspectionEvent[1].user.fullname ? historyInspectionEvent[1].user.fullname : "",
                    "role": historyInspectionEvent[1] && historyInspectionEvent[1].performerRole ? historyInspectionEvent[1].performerRole : "",
                    "status": historyInspectionEvent[1] && historyInspectionEvent[1].inspectionStatus && historyInspectionEvent[1].inspectionStatus.name ? historyInspectionEvent[1].inspectionStatus.name : "ALL",
                    "label": historyInspectionEvent[1] && historyInspectionEvent[1].inspectionStatus && historyInspectionEvent[1].inspectionStatus.label ? historyInspectionEvent[1].inspectionStatus.label : ""
                }
            }
        }

        return (
            <Page
                title={label.InspectionEventDetail}
                breadcrumbs={[{ name: label.InspectionEventDetail, active: true }]}>
                {super.render()}
                <InspectionEventHistoryModal
                    showing={inspectionEventHistoryModal} onClose={()=>{
                    this.setState({
                        inspectionEventHistoryModal:false
                    })
                }} inspection={inspection}/>
                <ConfirmInspectionSuspendModal
                    inspection={inspection}
                    inspectionEvent={currentInspectionEvent}
                    showing={confirmInspectionSuspendModal}
                    okCallback={()=>{
                        this.setState({
                            confirmInspectionSuspendModal:false
                        }, () => {
                            this.suspendInspection()
                        })
                    }}
                    cancelCallback={()=>{
                        this.setState({confirmInspectionSuspendModal:false})
                    }}
                />
                <ReopenInspectionConfirmModal
                    show={reopenInspectionConfirmModal}
                    inspection={inspection}
                    onClickOk={() => {
                        this.setState({
                            reopenInspectionConfirmModal:false,
                        }, () => {
                            this.reopenInspection()
                        })
                    }}
                    onClickCancel={() => {
                        this.setState({
                            inspectionEvent:{},
                            reopenInspectionConfirmModal:false,
                        })
                    }}
                />
                <ModelModal
                    show={modelModal}
                    model={model}
                    onClickOk={()=>{
                        this.setState({modelModal:false})
                    }}/>
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader
                                style={{cursor:'pointer'}}
                                onClick={event=>{
                                    this.setState({
                                        userFilterExpand:!this.state.userFilterExpand
                                    })
                                }}>
                                {
                                    (this.state.userFilterExpand)?(<FiArrowUpCircle/>):((<FiArrowDownCircle/>))
                                }&nbsp;{label.InspectionEventDetail}&nbsp;({label.ClickToExpandMoreInformation})
                            </CardHeader>
                            <CardBody>
                                <Collapse isOpen={this.state.userFilterExpand}>
                                    <Row>
                                        <Col md={5}>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>{label.PerformedBy}</CardHeader>
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <MyCardImage
                                                                        top
                                                                        src={performer.imageUrl ? performer.imageUrl : userProfilePicture
                                                                        }
                                                                        onError={(elm) => this.defaultImage(elm, userProfilePicture)}
                                                                        onClick={() => {
                                                                            if (performer.imageUrl) {
                                                                                this.openLightBoxSingleImage(performer.imageUrl)
                                                                            }
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col md={8}>

                                                                    {lastPerformerAndStatus?(
                                                                        <div>
                                                                            <Row>
                                                                        <Col md={4}>
                                                                            {label.LastPerformer}
                                                                        </Col>
                                                                        <Col md={8}>
                                                                            <span>
                                                                                {
                                                                                    (currentInspectionEvent.user && currentInspectionEvent.user.id) ? (
                                                                                        <a href={'/userDetail?id=' + currentInspectionEvent.user.id}>{lastPerformerAndStatus.name} -  {lastPerformerAndStatus.role}</a>
                                                                                    ) :
                                                                                        (<span>{lastPerformerAndStatus.name} -  {lastPerformerAndStatus.role}</span>)
                                                                                }
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            {label.LastStatus}
                                                                        </Col>
                                                                        <Col md={8}>
                                                                            <span
                                                                                style={
                                                                                    {
                                                                                        background: INSPECTION_STATUS_COLOR[lastPerformerAndStatus.status],
                                                                                        paddingLeft: '8px', paddingRight: '8px', paddingTop: '4px', paddingBottom: '4px', color: 'white',
                                                                                        fontWeight: 'bold', borderRadius: '8px'
                                                                                    }
                                                                                }>
                                                                                {lastPerformerAndStatus.label}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                        </div>
                                                                    ):null}
                                                                    
                                                                    {previousPerformerAndStatus ?
                                                                    (<div>
                                                                        <Row>
                                                                        <Col md={4}>
                                                                            {label.PreviousPerformer}
                                                                        </Col>
                                                                        <Col md={8}>
                                                                            <span>
                                                                                {
                                                                                    (currentInspectionEvent.user && currentInspectionEvent.user.id) ? (
                                                                                        <a href={'/userDetail?id=' + currentInspectionEvent.user.id}>{previousPerformerAndStatus.name} -  {previousPerformerAndStatus.role}</a>
                                                                                    ) :
                                                                                        (<span>{previousPerformerAndStatus.name} -  {previousPerformerAndStatus.role}</span>)
                                                                                }
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                        <Row>
                                                                            <Col md={4}>
                                                                                {label.PreviousStatus}
                                                                            </Col>
                                                                            <Col md={8}>
                                                                                <span
                                                                                    style={
                                                                                        {
                                                                                            background: INSPECTION_STATUS_COLOR[previousPerformerAndStatus.status],
                                                                                            paddingLeft: '8px', paddingRight: '8px', paddingTop: '4px', paddingBottom: '4px', color: 'white',
                                                                                            fontWeight: 'bold', borderRadius: '8px'
                                                                                        }
                                                                                    }>
                                                                                    {previousPerformerAndStatus.label}
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>):null}

                                                                    <Row>
                                                                        <Col md={4}>
                                                                            {label.LastModification}
                                                                        </Col>
                                                                        <Col md={8}>
                                                                            <span>
                                                                                {parseDate(currentInspectionEvent.created, DD_MM_YYYY_HH_MM_SS)}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <Typography
                                                                        style={{textAlign:'center', fontSize:'32px', cursor:'hover'}}
                                                                        type={"text-light bg-dark"}>{inspection.reference}</Typography>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Typography
                                                                        block
                                                                        type="text-white bg-primary"
                                                                        style={{textAlign:'center', fontSize:'32px', cursor:'pointer'}}
                                                                        onClick={(e)=>{
                                                                            e.preventDefault()
                                                                            this.setState({
                                                                                inspectionEventHistoryModal:true
                                                                            })
                                                                        }}
                                                                    >
                                                                        {label.History}&nbsp;&nbsp;&nbsp;<MdHistory/>
                                                                    </Typography>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={7}>
                                            <Card>
                                                <CardHeader>{label.Model}</CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="systemType">{label.SystemType}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="systemType"
                                                                    readOnly={true}
                                                                    value={model.systemType}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="modelName">{label.ModelName}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="modelName"
                                                                    readOnly={true}
                                                                    value={model.modelName}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={1}>
                                                            <FormGroup>
                                                                <Label>&nbsp;</Label>
                                                                <Button
                                                                    className={"form-control"}
                                                                    onClick={() => {
                                                                        this.setState({modelModal:true})
                                                                    }}
                                                                >
                                                                    <MdDetails/>
                                                                </Button>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Label>{label.CurrentInspectionStatus}</Label>
                                                                <Input
                                                                    className={"inspection-status-"+(inspectionStatus.name?inspectionStatus.name.toLowerCase():"")}
                                                                    type="text"
                                                                    name="inspectionStatus"
                                                                    readOnly={true}
                                                                    value={inspectionStatus.label}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="description">{label.SerialCode}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="serialCode"
                                                                    readOnly={true}
                                                                    value={inspection.serialCode}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="customerOrderNo">{label.CustomerOrderNo}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="customerOrderNo"
                                                                    readOnly={true}
                                                                    value={inspection.customerOrderNo}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label>{label.Client}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="client"
                                                                    readOnly={true}
                                                                    value={inspection.client}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="productSKU">{label.ProductSKU}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="productSKU"
                                                                    readOnly={true}
                                                                    value={inspection.productSKU}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="productName">{label.ProductName}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="productName"
                                                                    readOnly={true}
                                                                    value={inspection.productName}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label>{label.InspectionNote}</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="inspectionNote"
                                                                    readOnly={true}
                                                                    value={inspectionEvent.inspectionNote}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label for="description">{label.Description}</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="description"
                                                                    readOnly={true}
                                                                    value={model.description}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Collapse>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                <ButtonGroup className="float-right">
                                                    {
                                                        (inspectionEvent.canReprint)&&(
                                                            <>
                                                                <a
                                                                    target="_blank"
                                                                    href={Global.API.INSPECTION_PDF_REPORT
                                                                        +"/external?reference="+inspection.reference+"&identifier="+admin.adminCode}>
                                                                    <Button color={"primary"}>
                                                                        {label.ExternalPrint}&nbsp;<MdPrint/>
                                                                    </Button>
                                                                </a>
                                                                &nbsp;
                                                                <a
                                                                    target="_blank"
                                                                    href={Global.API.INSPECTION_PDF_REPORT
                                                                        +"/internal?reference="+inspection.reference+"&identifier="+admin.adminCode}>
                                                                    <Button color={"purple"}>
                                                                        {label.InternalPrint}&nbsp;<MdPrint/>
                                                                    </Button>
                                                                </a>
                                                                &nbsp;
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (inspectionEvent.canSuspend)&&(
                                                            <>
                                                                <Button
                                                                    style={{marginRight:'6px'}}
                                                                    color={"danger"}
                                                                    onClick={() => {
                                                                        this.setState({confirmInspectionSuspendModal:true})
                                                                    }}>
                                                                    {label.SuspendInspection}&nbsp;<CgDanger/>
                                                                </Button>
                                                                &nbsp;
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (inspectionEvent.canReopen)&&(
                                                            <>
                                                                <Button
                                                                    style={{marginRight:'6px'}}
                                                                    color={"primary"}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            reopenInspectionConfirmModal:true
                                                                        })
                                                                    }}
                                                                >
                                                                    {label.Reopen}&nbsp;<FiBookOpen/>
                                                                </Button>
                                                                &nbsp;
                                                            </>
                                                        )
                                                    }
                                                </ButtonGroup>
                                                <Button
                                                    style={{marginRight:'6px'}}
                                                    color={stepNumber==0 && !modeRectifierControllerSerialNumbers?"disabled":"warning"}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.previousPage()
                                                    }}>
                                                    {label.PagePrevious}
                                                </Button>
                                                <Button
                                                    color={modeRectifierControllerSerialNumbers?"disabled":"primary"}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.nextPage()
                                                    }}>
                                                    {label.PageNext}
                                                </Button>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        {
                                                            modeRectifierControllerSerialNumbers?
                                                                (
                                                                    <InspectionEventRectifierControllerSerialNumber
                                                                        inspection={inspectionEvents}
                                                                        inspectionEvent={inspectionEvent}
                                                                        rectifierControllerSerialNumbers={inspectionEvent.rectifierControllerSerialNumbers}/>
                                                                )
                                                                :
                                                                (
                                                                    <InspectionEventFormLaneWidget
                                                                        inspection={inspection}
                                                                        inspectionEvent={inspectionEvent}
                                                                        stepFormLaneId={formLaneApplications[stepNumber]?formLaneApplications[stepNumber].laneId:0}
                                                                        formLaneApplication={formLaneApplications[stepNumber]?formLaneApplications[stepNumber]:{}}/>
                                                                )
                                                        }
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <SockJsClient
                    url={Global.BASE_URL}
                    topics={[Global.API.LISTEN_INSPECTION+"/"+inspection.id]}
                    getRetryInterval={5}
                    heartbeat={60000}
                    onMessage={(message) => {
                        this.successToast(message)
                        this.fetchInspectionEventDetail()
                    }}
                    ref={ (client) => { this.clientRef = client }} />
            </Page>
        );
    }
}

export default Object.assign(InspectionEventDetailPage, {ENDPOINT : "/inspectionEventDetail"})