import React from 'react';
import Modal from 'react-awesome-modal';
import PropTypes from 'prop-types';
import {Progress} from 'reactstrap';

export default class ProgressBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      percentage: 0,
      show: this.props.show,
      interval:null
    }
    this.increment = this.increment.bind(this)
  }
  componentWillReceiveProps(props, nextContext) {
      if(this.props!=props){
          this.setState({
              show: props.show
          }, () => {
              if(this.state.show){
                  clearInterval(this.state.interval);
                  let interval = setInterval(() => {
                      this.increment()
                  }, 200);
                  this.setState({
                      interval:interval
                  })
              }else{
                  clearInterval(this.state.interval);
                  this.setState({
                      interval:null
                  })
              }
          })
      }
  }

  componentDidMount() {
      // clearInterval(this.interval);
  }

  componentWillUnmount() {
      clearInterval(this.state.interval);
      this.setState({
          interval:null
      })
  }

  increment() {
    let percentage = this.state.percentage
    if(percentage >= 100){
       percentage = 0
    }
    this.setState({ percentage: percentage + 20 }, () => {
        this.forceUpdate()
    })
  }

  render() {
    return (
        <Modal visible={this.state.show} width="350px" height="15px" effect="fadeInDown" onClickAway={() => {}}>
            <Progress
                color={"primary"}
                animated
                value={this.state.percentage}
                className="mb-3"
            />
        </Modal>
    )
  }
}

// const Filler = (props) => {
//   return <div className="filler" style={{ width: `${props.percentage}%` }} />
// }

ProgressBar.propTypes = {
    show: PropTypes.bool,
};
