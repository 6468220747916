import BaseComponent from './BaseComponent';
import React from 'react';
import {MdCheck} from 'react-icons/md';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';

let checkedBgColor = "#00f50d"
let unCheckedBgColor = "#d4d8ee"
let checkedColor = ""
let unCheckedColor = ""


export default class Checkbox extends BaseComponent{
    constructor(props) {
        super(props);
        this.state ={
            checked:this.props.checked
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            this.setState({
                checked:props.checked
            }, () => {
                // if(this.props.onChange){
                //     this.props.onChange(this.state.checked)
                // }
            })
        }
    }

    render() {
        let finalBgColor = this.state.checked?checkedBgColor:unCheckedBgColor
        let {formMode} = this.props
        let style = {backgroundColor:finalBgColor}
        if(formMode){
            style = {...style, float:'left', position:'absolute', display:'block'}
        }

        return (
            <Button
                color="none"
                style={style}
                onClick={
                    e=>{
                        e.preventDefault()
                        this.setState({
                            checked:!this.state.checked
                        },() => {
                            if(this.props.onChange){
                                this.props.onChange(this.state.checked)
                            }
                        })
                    }
                }>
                <MdCheck color="white"/>
            </Button>
        );
    }
}

Checkbox.propTypes = {
    checked:PropTypes.bool,
    onChange:PropTypes.func
}
