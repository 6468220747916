import React from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import PropTypes from 'prop-types';
import label from '../../message/Label'

export default class ActiveOption extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            callback : this.props.callback,
            default:this.props.default?this.props.default:false,
            label:this.props.label
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props!=nextProps){
            this.setState({
                callback : nextProps.callback,
                default:nextProps.default?nextProps.default:false
            })
        }


    }

    render() {

        let labelText = this.state.label?this.state.label:label.SelectStatus
        let trueLabel = this.props.trueLabel?this.props.trueLabel:label.Active
        let falseLabel = this.props.falseLabel?this.props.falseLabel:label.Inactive

        return (
            <FormGroup>
                <Label for="select">{labelText}</Label>
                <Input type="select"
                       name="select"
                       value={this.state.default}
                       onChange={e=>{
                           e.preventDefault()
                           let value = e.target.value
                           this.setState({
                               default:value
                           }, () => {
                               if(this.props.callback){
                                   this.props.callback(value)
                               }
                           })
                       }}>
                    <option value={true}>{trueLabel}</option>
                    <option value={false}>{falseLabel}</option>
                </Input>
            </FormGroup>
        );
    }
}

ActiveOption.propTypes = {
    label:PropTypes.string,
    callback : PropTypes.func,
    default:PropTypes.any,
    trueLabel:PropTypes.string,
    falseLabel:PropTypes.string,

}
