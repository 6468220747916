import BasePage from './BasePage';
import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import {changeParam3, isEmpty, navigatePage, parseDate, sortirMap} from '../utils/Utilities';
import {IoMdSettings} from 'react-icons/io';
import SearchInput from '../components/SearchInput';
import Global, {DD_MM_YYYY_HH_MM_SS, INSPECTION_STATUS_COLOR} from '../utils/Global';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import SockJsClient from 'react-stomp';
import label from '../message/Label'
import {FiArrowDownCircle, FiArrowUpCircle} from "react-icons/fi";
import DateInput from "../components/Widget/DateInput";
import ItemOption from "../components/Widget/ItemOption";
import {MdRestore} from "react-icons/md";
import {FaFileExcel} from "react-icons/fa";
import Page from "../components/Page";
import {inspectionEventFromNative, inspectionEventsFromNative} from "../utils/MyUtilities";


class InspectionEventPage extends BasePage{

    constructor(props) {
        super(props);
        this.state ={
            ascending:false,
            sortir:'jie.updated',
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            key:queryString.parse(this.props.query).tab?queryString.parse(this.props.query).tab:label.All,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            createdStartDate:null,
            createdEndDate:null,
            inspectionStatuses:[],
            inspectionEvents:[],
            models:[],
            model:{},
            inspectionStatus:{},
            inputSearch : queryString.parse(this.props.query).search?queryString.parse(this.props.query).search:"",
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchInspectionStatus()
        this.fetchModels()
    }

    fetchModels = () =>{
        this.get(Global.API.MODELS, null, null, response=>{
            if(response.code===200){
                this.setState({
                    models : response.data
                }, () => {
                    this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
                })
            }
        }, true, true)
    }


    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            let page = queryString.parse(props.query).page?queryString.parse(props.query).page:1
            let key = queryString.parse(props.query).tab?queryString.parse(props.query).tab:label.All
            let search = queryString.parse(props.query).search?queryString.parse(props.query).search:""

            this.setState({
                page:page,
                key:key,
                search:search,
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
            })
        }
    }


    fetchInspectionStatus = () =>{
        this.get(Global.API.INSPECTION_STATUSES, null, null, response=>{
            if(response.code===200){
                this.setState({
                    inspectionStatuses : response.data
                }, () => {
                    this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
                })
            }
        }, true, true)
    }

    fetchAll = (ascending, sortir, search, progressing, excelMode) =>{
        let params = {
            inspectionStatusId:this.state.inspectionStatus.id,
            ascending:ascending,
            sortir:sortir,
            search:search,
            page:this.state.page-1,
            createdStartDate:this.state.createdStartDate,
            createdEndDate:this.state.createdEndDate,
            modelId:this.state.model.id,
            excelMode:excelMode

        }
        if(excelMode){
            this.asyncDownloadGet(Global.API.INSPECTION_EVENTS, {params:params}, null,true);
            return
        }
        this.get(Global.API.INSPECTION_EVENTS, {params:params}, null, res =>{
            if(res.code === 200){
                console.log("inspection Events:",res.data)
                let inspectionEvents = inspectionEventsFromNative(res.data);
                this.setState({
                    inspectionEvents : inspectionEvents,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, progressing, true);
    }

    refreshInspections = (ascending, sortir, progressing) => (
        this.setState({
            ascending:ascending,
            sortir:sortir,
        }, () => {
            this.fetchAll(ascending, sortir, this.state.inputSearch, progressing)
        })
    )

    resetFilter(){
        this.setState({
            search:"",
            page :1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            createdStartDate:null,
            createdEndDate:null,
            inspectionEvents:[],
            inspectionStatus: {},
            model: {},
            inputSearch : "",
        }, () => {
            navigatePage(this.props, 1)
        })
    }



    render() {
        let {brandCategories, brandCategory, inspectionStatuses, inspectionStatus, models, model, totalElement, totalPage} = this.state

        return (
            <Page
                title={label.CheckingList}
                breadcrumbs={[{ name: label.CheckingList, active: true }]}
                className="TablePage"
            >
                {super.render()}
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader style={{cursor:'pointer'}} onClick={event=>{
                                this.setState({
                                    userFilterExpand:!this.state.userFilterExpand
                                })
                            }}>
                                {
                                    (this.state.userFilterExpand)?(<FiArrowUpCircle/>):((<FiArrowDownCircle/>))
                                }
                                &nbsp;{label.AdvanceFilter} ({label.ClickToExpandFilterContent})
                            </CardHeader>
                            <Collapse isOpen={this.state.userFilterExpand}>
                                <CardHeader>
                                    <Row>
                                        <Col md={3}>
                                            <Label for="createdStartDate">{label.CreatedStartDate}</Label>
                                            <DateInput
                                                id="createdStartDate"
                                                value={this.state.createdStartDate}
                                                maxdate={this.state.createdEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdStartDate : value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="createdEndDate">{label.CreatedEndDate}</Label>
                                            <DateInput
                                                id="createdEndDate"
                                                mindate={this.state.createdStartDate}
                                                value={this.state.createdEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdEndDate :  value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectStatus}
                                                    fieldForLabel={"label"}
                                                    objects={inspectionStatuses}
                                                    default={!isEmpty(inspectionStatus)?inspectionStatus.id:null}
                                                    callback={(inspectionStatus)=>{
                                                        this.setState({
                                                            inspectionStatus:inspectionStatus
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectSystemType}
                                                    objects={models}
                                                    default={!isEmpty(model)?model.id:null}
                                                    callback={(model)=>{
                                                        this.setState({
                                                            model:model
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>{label.Searching}</Label>
                                                <SearchInput
                                                    placeholder={label.SearchReferenceSerialModelUser}
                                                    value={this.state.inputSearch}
                                                    onChange={e=>{
                                                        this.setState({
                                                            inputSearch:e.target.value
                                                        }, () => {
                                                            if(this.state.inputSearch===''){
                                                                changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
                                                            }
                                                        })
                                                    }}
                                                    onEnter={e=>{
                                                        if(this.state.inputSearch!==''){
                                                            changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalRecord}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color:'green'}}><strong>{totalElement}</strong></h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalPage}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color:'blue'}}>{totalPage}</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={6}>
                                                    <Button block className={"float-left"} color={"primary"}
                                                            onClick={event => {
                                                                navigatePage(this.props, 1)
                                                            }}><IoMdSettings/>&nbsp;{label.ApplyFilter}</Button>
                                                </Col>
                                                <Col md={6}>
                                                    <Button block className={"float-left"} color={"yellow"}
                                                            onClick={event => {
                                                                this.resetFilter()
                                                            }}><MdRestore/>&nbsp;{label.ResetFilter}</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Button block className={"float-left"} color={"green"}
                                                            onClick={event => {
                                                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true, true)
                                                            }}><FaFileExcel/>&nbsp;{label.DownloadExcel}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                {this.renderInspectionEvent()}
            </Page>
        )
    }

    renderInspectionEvent = () =>{
        return (
            <Row key={2}>
                <Col>
                    <Card>
                        <CardHeader>
                            <strong>{label.Inspection}</strong>
                            <br/><br/>
                            <Row>
                                <Col md={3}>
                                    {label.SortBy} :
                                    <UncontrolledButtonDropdown key={1}>
                                        <DropdownToggle
                                            caret
                                            color="white"
                                            className="text-capitalize m-1">
                                            {
                                                sortirMap(this.state.sortir.toString())
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ji.reference", true))}>{label.Reference}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "jie.serialCode", true))}>{label.SerialCode}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "jm.systemType", true))}>{label.SystemType}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "jie.performerName", true))}>{label.PerformedBy}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "jie.created", true))}>{label.Created}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col md={3}>
                                    {label.Sortir} :
                                    <UncontrolledButtonDropdown key={2}>
                                        <DropdownToggle
                                            caret
                                            color="white"
                                            className="text-capitalize m-1">
                                            {
                                                this.state.ascending?label.Ascending:label.Descending
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={e=>(this.refreshInspections(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {this.renderInspectionEventTable()}
                        </CardBody>
                        <SockJsClient
                            url={Global.BASE_URL}
                            topics={[Global.API.LISTEN_UPDATE_INSPECTION_EVENTS]}
                            getRetryInterval={5}
                            heartbeat={60000}
                            onMessage={(message) => {
                                this.successToast(message)
                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
                            }}
                            ref={ (client) => { this.clientRef = client }} />
                    </Card>
                </Col>
            </Row>
        )
    }


    renderInspectionEventTable = () =>{
        return (
            <Card>
                <CardBody>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.ReferenceCode}</th>
                            <th>{label.SerialCode}</th>
                            <th style={{width:'20%'}}>{label.Customer}</th>
                            <th>{label.SystemType}</th>
                            <th>{label.Category}</th>
                            <th>{label.PerformedBy}</th>
                            <th style={{width:'15%'}}>{label.Status}</th>
                            <th>{label.Created}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.inspectionEvents.map((item, index)=>(
                                <tr key={item.id}>
                                    <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                    <td><a href={'/inspectionEventDetail?id='+item.id}>{item.inspection.reference}</a></td>
                                    <td><a href={'/inspectionEventDetail?id='+item.id}>{item.serialCode}</a></td>
                                    <td>
                                        {label.OrderNo} : {item.customerOrderNo}<br/>
                                        {label.Client} : {item.client}<br/>
                                        {label.ProductSKU} : {item.productSKU}<br/>
                                        {label.ProductName} : {item.productName}<br/>
                                    </td>
                                    <td>{item.inspection.model.systemType}</td>
                                    <td>{item.inspection.model.modelCategory.name}</td>
                                    <td>
                                        {
                                            item.user?(
                                                    <a href={'/userDetail?id='+item.user.id}>{item.performerName}&nbsp;-&nbsp;{item.performerRole}</a>
                                                ):
                                                (<span>{item.performerName}&nbsp;-&nbsp;{item.performerRole}</span>)
                                        }
                                    </td>
                                    <td>
                                        <span
                                            style={
                                                {
                                                    background:INSPECTION_STATUS_COLOR[item.inspectionStatus.name],
                                                    paddingLeft:'8px', paddingRight:'8px', paddingTop:'4px', paddingBottom:'4px', color:'white',
                                                    fontWeight:'bold', borderRadius:'8px'
                                                }
                                            }>
                                            {item.inspectionStatus.label}
                                        </span>
                                    </td>
                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
                <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
            </Card>
        )
    }
}

export default Object.assign(InspectionEventPage, {ENDPOINT : "/inspectionEvents"})
