import React from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import ReactJson from 'react-json-view';
import PropTypes from 'prop-types';
import BaseComponent from '../BaseComponent';
import Label from 'reactstrap/es/Label';
import Typography from '../Typography';
import label from '../../message/Label'
/*= (message, okCallback, cancelCallback) =>*/
export default class JsonDialog extends BaseComponent{

    constructor(props){
        super(props);
        this.state = {
            modal: this.props.showing,
            backdrop: false,
            title : this.props.title,
            json : this.props.json,
            previousJsonData : this.props.previousJsonData,
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props.modal!=props.showing){
            this.setState({
                modal:props.showing,
                title : props.title,
                json : props.json,
                previousJsonData : props.previousJsonData,
            })
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    render(){
        let json = this.state.json
        let previousJsonData = this.state.previousJsonData
        return (
            <Modal
                centered={true}
                size="lg"
                isOpen={this.state.modal}
                backdrop={false}>
                <ModalHeader>
                    {this.state.title}
                </ModalHeader>
                <ModalBody>
                    {
                        (previousJsonData!=null)?
                            (
                                <span>
                                    <Row>
                                        <Col>
                                            <Label><Typography><span style={{color:'#00a80c'}}>Before</span></Typography></Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <ReactJson src={previousJsonData} />
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label><Typography><span style={{color:'#0222ff'}}>After</span></Typography></Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ReactJson src={json} />
                                        </Col>
                                    </Row>
                                </span>
                            )
                                :
                            (<ReactJson src={json} />)
                    }
                    </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={e=>{
                        this.setState({
                            modal:false
                        }, () => {
                            if(this.props.okCallback!=null){
                                this.props.okCallback()
                            }
                        })
                    }}>
                        {label.Ok}
                    </Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

JsonDialog.propTypes = {
    showing:PropTypes.bool,
    json:PropTypes.object.isRequired,
    previousJsonData:PropTypes.object,
    title:PropTypes.string,
    okCallback:PropTypes.func
}
