import AuthForm, {STATE_LOGIN} from 'components/AuthForm';
import React from 'react';
import {Card, Col, Row} from 'reactstrap';
import queryString from "query-string";
import BaseComponent from "../components/BaseComponent";

class AuthPage extends BaseComponent {

    constructor(props) {
        super(props);
        let state = this.state
        state.message = queryString.parse(this.props.query).message
        this.setState(state)
    }


    handleAuthState = authState => {
        if (authState === STATE_LOGIN) {
            this.props.history.push('/login');
        } else {
            this.props.history.push('/signup');
        }
    };

    handleLogoClick = () => {
        this.props.history.push('/');
    };

    render() {

        let {message} = this.state
        return (
            <Row
                style={{
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Col md={6} lg={4}>
                    <Card body>
                        <AuthForm
                            authState={this.props.authState}
                            onChangeAuthState={this.handleAuthState}
                            onLogoClick={this.handleLogoClick}
                            message={message}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default Object.assign(AuthPage, {ENDPOINT: "/login"})
