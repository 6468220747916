import * as React from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import BaseComponent from '../BaseComponent';
import * as PropTypes from 'prop-types';
import { Form } from '../../model/formbuilder/Form.js';
import ItemOption from '../Widget/ItemOption';
import { Option } from '../../model/formbuilder/Option';
import { FormType } from '../../model/formbuilder/FormType';
import Global from '../../utils/Global';
import { getData } from '../../utils/StorageUtil';
import Card from 'reactstrap/es/Card';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import { QueryParam } from '../../model/formbuilder/QueryParam';
import WarningLabel from '../Widget/WarningLabel';
import { MdAdd, MdDelete } from 'react-icons/md';
import label from '../../message/Label'
import Checkbox from "../Checkbox";

import { cloneDeep } from "lodash"


/*= (message, okCallback, cancelCallback) =>*/

const dataTags = new Object();
export default class FormModal extends BaseComponent {

    props = {}

    validator = {
        tag: "",
        title: "'",
        placeHolder: "",
        type: "",
        fetchApi: "",
        queryParams: "",
        imageDirectory: "",
        positiveValue: "",
        negativeValue: "",
    }

    typeOptions = [
        { id: 1, name: FormType.TEXT },
        { id: 2, name: FormType.POSITIVE_NEGATIVE },
        // {id:3, name:FormType.EMAIL},
        // {id:4, name:FormType.NUMBER},
        // {id:5, name:FormType.PHONE},
        // {id:6, name:FormType.CURRENCY},
        // {id:7, name:FormType.DATE},
        { id: 8, name: FormType.IMAGE },
        // {id:9, name:FormType.DROPDOWN_DATA_API},
    ]

    subInputTypeOptions = [
        { id: 1, name: FormType.TEXT },
        { id: 2, name: FormType.POSITIVE_NEGATIVE },
        // {id:3, name:FormType.EMAIL},
        // {id:4, name:FormType.NUMBER},
        // {id:5, name:FormType.PHONE},
        // {id:6, name:FormType.CURRENCY},
        // {id:7, name:FormType.DATE},
        { id: 8, name: FormType.IMAGE },
    ]


    mandatoryOptions = [
        { id: 1, name: false },
        { id: 2, name: true }
    ]
    hidePrintOutOptions = [
        { id: 1, name: false },
        { id: 2, name: true }
    ]


    constructor(props) {
        super(props);
        let form = this.props.form ? this.props.form : new Form()
        let subInputs = form.subInputs ? form.subInputs : []
        let subInput = {}
        let modeCreate = false;
        let previousTag = "";
        let lanes = this.props.lanes ? this.props.lanes : []
        let lane = (!this.props.lane ? (lanes && lanes.length > 0 ? lanes[0] : null) : this.props.lane)
        if (!form.id) {
            modeCreate = true;
            form.id = new Date().getTime()
        } else {
            modeCreate = false;
            previousTag = form.tag
        }
        if (modeCreate) {
            lanes.forEach((lane1, laneIndex) => {
                if (lane1.id == lane.id) {
                    form.tag = (laneIndex + 1) + "." + (lane1.cards.length + 1)
                }
            })
        }

        this.state = {
            diffLane: false,
            groupForm: this.props.groupForm,
            changePosition: false,
            board: this.props.board,
            modal: this.props.showing,
            onAdd: this.props.onAdd,
            close: this.props.close,
            form: form,
            position: cloneDeep(form),
            original: cloneDeep(form),
            subInputs: subInputs,
            subInput: subInput,
            lanes: lanes,
            lane: lane,
            previousTag: previousTag,
            typeOption: this.typeOptions[0],
            mandatoryOption: this.mandatoryOptions[1],
            hidePrintOutOption: this.hidePrintOutOptions[0],
            validator: this.validator,
            cardSelected: this.props.lanes && Object.keys(this.props.lanes).length > 0 ? this.props.lanes[0] : []
        }
        this.buttonClick = this.buttonClick.bind(this)
    }

    componentWillReceiveProps(props, nextContext) {
        if (this.props.showing != props.showing) {

            let form = props.form ? props.form : new Form();
            let subInputs = form.subInputs ? form.subInputs : []
            let subInput = {}
            let modeCreate = false;
            let previousTag = "";
            if (!form.id) {
                modeCreate = true;
                form.id = new Date().getTime()
            } else {
                modeCreate = false;
                previousTag = form.tag
            }
            let lanes = props.lanes ? props.lanes : []
            let lane = (!props.lane ? (lanes && lanes.length > 0 ? lanes[0] : null) : props.lane)
            if (modeCreate) {
                lanes.forEach((lane1, laneIndex) => {
                    if (lane1.id == lane.id) {
                        form.tag = (laneIndex + 1) + "." + (lane1.cards.length + 1)
                    }
                })
            }
            let typeOption = this.typeOptions.find(value => {
                if (value.name === form.type) {
                    return value;
                }
            })
            let mandatoryOption = this.mandatoryOptions.find(value => {
                if (value.name === form.mandatory) {
                    return value;
                }
            })
            let hidePrintOutOption = this.hidePrintOutOptions.find(value => {
                if (value.name === form.hidePrintOut) {
                    return value;
                }
            })

            this.setState({
                diffLane: false,
                groupForm: props.groupForm,
                board: props.board,
                modal: props.showing,
                form: form,
                position: cloneDeep(form),
                original: cloneDeep(form),
                subInputs: subInputs,
                subInput: subInput,
                lanes: lanes,
                lane: lane,
                previousTag: previousTag,
                typeOption: typeOption ? typeOption : this.typeOptions[0],
                mandatoryOption: mandatoryOption ? mandatoryOption : this.mandatoryOptions[1],
                hidePrintOutOption: hidePrintOutOption ? hidePrintOutOption : this.hidePrintOutOptions[0],
                validator: this.validator,
                close: props.close,
                cardSelected: this.props.lanes && Object.keys(this.props.lanes).length > 0 ? this.props.lanes[0] : []
            })
        }
    }

    buttonClick(e, button) {
        e.preventDefault()
        if (button === label.Ok) {
            if (this.props.okCallback != null) {
                this.props.okCallback()
            }
        } else {
            if (this.props.cancelCallback != null) {
                this.props.cancelCallback()
            }
        }
    }

    close = () => {
        this.setState({
            modal: false,
        }, () => {
            this.props.close()
        })
    }

    renderFormApi() {
        let configuration = getData(Global.CONFIGURATION)
        if (!configuration) {
            configuration = {}
        }


        let form = this.state.form
        let validator = this.state.validator

        return (
            <>
                <Card>
                    <CardHeader>
                        {label.ApiDescription}
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm={5}>
                                <FormGroup>
                                    <Label for="name">Base API</Label>
                                    <Input
                                        type="text"
                                        value={(configuration.baseApi ? configuration.baseApi : "")}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={7}>
                                <FormGroup>
                                    <Label for="name">Path</Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={form.fetchApi ? form.fetchApi : ""}
                                        onChange={(e) => {
                                            form.fetchApi = e.target.value
                                            this.setState({
                                                form: form
                                            })
                                        }}
                                        placeholder="/some/service/ or /some/service/{query}"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={e => {
                                    e.preventDefault()
                                    let queryParams = form.queryParams
                                    queryParams.push(new QueryParam())
                                    form.queryParams = queryParams
                                    this.setState({
                                        form: form
                                    })
                                }}>Add Query</Button>
                            </Col>
                        </Row>
                        <br />
                        {
                            form.queryParams.map((value, index) => {
                                return (
                                    <Row key={index + 1}>
                                        <Col sm={5}>
                                            <FormGroup>
                                                <Label for="name">Query Key</Label>
                                                <Input
                                                    type="text"
                                                    name="name"
                                                    value={value.key ? value.key : ""}
                                                    onChange={(e) => {
                                                        value.key = e.target.value
                                                        this.setState({
                                                            form: form
                                                        })
                                                    }}
                                                    placeholder={label.EnterQueryKey}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={5}>
                                            <FormGroup>
                                                <Label for="name">Query Reference</Label>
                                                <Input
                                                    type="text"
                                                    name="name"
                                                    value={value.reference ? value.reference : ""}
                                                    onChange={(e) => {
                                                        value.reference = e.target.value
                                                        this.setState({
                                                            form: form
                                                        })
                                                    }}
                                                    placeholder={label.EnterReference}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <br />
                                            <Button color="primary" onClick={e => {
                                                let queryParams = form.queryParams
                                                queryParams.splice(index, 1)
                                                form.queryParams = queryParams
                                                this.setState({
                                                    form: form
                                                })
                                            }}>
                                                <MdDelete color={"warning"} />
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                        <WarningLabel show={validator.fetchApi} message={validator.fetchApi} />
                    </CardBody>
                </Card>
                <br />
            </>

        )
    }

    renderFormPositiveNegative() {
        let configuration = getData(Global.CONFIGURATION)
        if (!configuration) {
            configuration = {}
        }

        let configurationAdmin = getData(Global.CONFIGURATION_ADMIN)
        if (configurationAdmin === null) {
            configurationAdmin = {}
        }

        let form = this.state.form
        let validator = this.state.validator

        return (
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="name">{label.PositiveLabel}</Label>
                        <Input
                            type="text"
                            name="positiveValue"
                            value={form.positiveValue ? form.positiveValue : ""}
                            onChange={(e) => {
                                form.positiveValue = e.target.value
                                validator.positiveValue = ""
                                this.setState({
                                    form: form,
                                    validator: validator
                                })
                            }}
                            placeholder={label.EnterPositiveLabel}
                        />
                        <WarningLabel show={validator.positiveValue} message={label.EnterPositiveLabel} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="name">{label.NegativeLabel}</Label>
                        <Input
                            type="text"
                            name="negativeValue"
                            value={form.negativeValue ? form.negativeValue : ""}
                            onChange={(e) => {
                                form.negativeValue = e.target.value
                                validator.negativeValue = ""
                                this.setState({
                                    form: form,
                                    validator: validator
                                })
                            }}
                            placeholder={label.EnterNegativeLabel}
                        />
                        <WarningLabel show={validator.negativeValue} message={label.EnterNegativeLabel} />
                    </FormGroup>
                </Col>
            </Row>
        )
    }

    renderSubField() {
        let { subInputs, form, validator } = this.state
        subInputs.forEach((value, index) => {
            value.tag = form.tag + "." + (index + 1)
            subInputs[index].tag = form.tag + "." + (index + 1)
        })
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            {label.SubInput}
                            <Button style={{ float: 'right' }} onClick={() => {
                                subInputs.push({ type: FormType.TEXT })
                                this.setState({
                                    subInputs: subInputs
                                })
                            }}><MdAdd /></Button>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th width={'5%'}>#</th>
                                        <th width={'30%'}>{label.Type}</th>
                                        <th width={'50%'}>{label.Title}</th>
                                        <th>{label.Mandatory}</th>
                                        <th>{label.Remove}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        subInputs.map((item, index) => (
                                            <tr>
                                                <td>{item.tag}</td>
                                                <td>
                                                    <ItemOption
                                                        type="text"
                                                        name="name"
                                                        default={item.typeId}
                                                        objects={this.subInputTypeOptions}
                                                        hideLabel={true}
                                                        hideOptionLabel={true}
                                                        title={label.SelectType}
                                                        callback={(typeOption) => {
                                                            subInputs[index].typeId = typeOption.id
                                                            subInputs[index].type = typeOption.name
                                                            this.setState({
                                                                subInputs: subInputs
                                                            })
                                                        }}
                                                        placeholder={label.EnterFormType}
                                                    />
                                                </td>
                                                <td>
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            name="name"
                                                            value={item.title ? item.title : ""}
                                                            onChange={(e) => {
                                                                subInputs[index].title = e.target.value
                                                                this.setState({
                                                                    subInputs: subInputs,
                                                                })
                                                            }}
                                                            placeholder={label.EnterFormTitle}
                                                        />
                                                    </FormGroup>
                                                    {
                                                        (item.type === FormType.POSITIVE_NEGATIVE) && (
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>
                                                                            {label.PositiveLabel}
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="name"
                                                                            value={item.positiveValue ? item.positiveValue : ""}
                                                                            onChange={(e) => {
                                                                                subInputs[index].positiveValue = e.target.value
                                                                                this.setState({
                                                                                    subInputs: subInputs,
                                                                                })
                                                                            }}
                                                                            placeholder={label.EnterPositiveLabel}
                                                                        />
                                                                        <WarningLabel show={validator.positiveValue} message={validator.positiveValue} />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>
                                                                            {label.NegativeLabel}
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="name"
                                                                            value={item.negativeValue ? item.negativeValue : ""}
                                                                            onChange={(e) => {
                                                                                subInputs[index].negativeValue = e.target.value
                                                                                this.setState({
                                                                                    subInputs: subInputs,
                                                                                })
                                                                            }}
                                                                            placeholder={label.EnterNegativeLabel}
                                                                        />
                                                                        <WarningLabel show={validator.negativeValue} message={validator.negativeValue} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        checked={item.mandatory}
                                                        onChange={checked => {
                                                            subInputs[index].mandatory = checked
                                                            this.setState({
                                                                subInputs: subInputs,
                                                            })
                                                        }
                                                        } />
                                                </td>
                                                <td>
                                                    <Button
                                                        color={"danger"}
                                                        onClick={() => {
                                                            subInputs.splice(index, 1)
                                                            this.setState({
                                                                subInputs: subInputs
                                                            })
                                                        }}>
                                                        <MdDelete />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }


    onClickOk() {
        let { changePosition, form, position, board, lane, typeOption, mandatoryOption, hidePrintOutOption, validator, diffLane, original } = this.state

        let print = {
            form, lane, typeOption, mandatoryOption
        }
        if (!form.tag) {
            validator.tag = label.PleaseEnterFormTag
            this.setState({ validator: validator })
            return;
        }
        if (!form.title) {
            validator.title = label.PleaseEnterAFormTitle
            this.setState({ validator: validator })
            return;
        }

        if (!form.placeHolder) {
            validator.placeHolder = label.PleaseEnterPlaceHolder
            this.setState({ validator: validator })
            return;
        }

        if (typeOption.name === FormType.POSITIVE_NEGATIVE) {
            if (!form.positiveValue) {
                validator.positiveValue = label.PleaseEnterPositiveLabel
                this.setState({ validator: validator })
                return;
            }
            if (!form.negativeValue) {
                validator.negativeValue = label.PleaseEnterNegativeLabel
                this.setState({ validator: validator })
                return;
            }
        }

        if (typeOption.name === FormType.IMAGE) {
            // if(!form.imageDirectory){
            //     validator.imageDirectory = "Please enter an image directory"
            //     this.setState({validator:validator})
            //     return ;
            // }
        }
        if (typeOption.name === FormType.DROPDOWN_DATA_API) {
            // if(!form.fetchApi || form.fetchApi===''){
            //     validator.fetchApi = "Please enter a valid API path"
            //     this.setState({validator:validator})
            //     return ;
            // }
            // let containQuery = false ;
            // if(containQuery){
            //     if(form.queryParams==null || form.queryParams.length>=0){
            //         validator.fetchApi = "Please enter an additional query param"
            //         this.setState({validator:validator})
            //         return ;
            //     }
            // }
        }
        let subInputs = this.state.subInputs ? this.state.subInputs : []
        form.subInputs = subInputs
        form.type = typeOption.name
        form.mandatory = mandatoryOption.name
        form.hidePrintOut = hidePrintOutOption.name

        if (this.props.onAdd) {
            if (changePosition) {
                form.tag = original.tag
                const originalTag = form.tag;
                const targetTag = position.tag;
                const [originalLane, originalIndex] = form.tag.split(".");
                const [targetLane, targetIndex] = position.tag.split(".");

                const newBoard = board;
                const boardOnLane = newBoard.lanes[Number(originalLane) - 1];
                const targetCard = boardOnLane.cards.findIndex(it => it.tag === targetTag);
                const originalCard = boardOnLane.cards.find(it => it.tag === originalTag);
                boardOnLane.cards.splice(Number(originalIndex) - 1, 1);

                if (!diffLane) {
                    // boardOnLane.cards.splice(Number(targetIndex) - 1, 0, originalCard);
                    form.lane = lane
                    boardOnLane.cards.splice(Number(targetIndex) - 1, 0, form);
                    boardOnLane.cards.forEach((it, index) => {
                        it.id = index + 1;
                        it.tag = `${originalLane}.${index + 1}`
                    })
                } else {
                    const boardOnLaneDiff = newBoard.lanes[Number(targetLane) - 1];
                    // boardOnLaneDiff.cards.splice(Number(targetIndex) - 1, 0, originalCard);
                    form.lane = lane
                    boardOnLaneDiff.cards.splice(Number(targetIndex) - 1, 0, form);
                    boardOnLaneDiff.cards.forEach((it, index) => {
                        it.id = index + 1;
                        it.tag = `${targetLane}.${index + 1}`
                    })
                }
                this.props.onAdd(form, lane, newBoard)
            } else {
                this.props.onAdd(form, lane)
            }


            this.close()
        }

    }


    render() {

        let configuration = getData(Global.CONFIGURATION)
        if (!configuration) {
            configuration = {}
        }


        let { form, position, lanes, lane, diffLane } = this.state
        let pageOptions = new Array();
        if (!lanes) {
            lanes = new Array()
        }
        if (!lane) {
            lane = {}
        }
        let validator = this.state.validator

        lanes.forEach(value => {
            let pageOption = new Option();
            pageOption.id = value.id
            pageOption.name = value.title
            pageOptions.push(pageOption)
        })

        let board = this.state.board
        if (!board) {
            board = {}
        }
        let cardSelected = []
        if (lane && lanes && Object.keys(lanes).length > 0) {
            lanes.map((value) => {
                if (value && value.cards && Object.keys(value.cards).length > 0 && value.id == lane.id) {
                    cardSelected = value.cards

                    cardSelected.forEach((it, index) => {
                        const [originalLane, originalIndex] = it.tag.split(".");
                        it.tag = `${originalLane}.${index + 1}`
                    })

                    if (diffLane || position.tag ) {
                        let notExist =  !cardSelected.find(it => it.tag === position.tag )
                        if(notExist){
                            cardSelected.push(position)
                        }

                        cardSelected.map((value) => {
                            dataTags[value.tag] = value;
                        })
                    }
                 
                }else{
                    let notExist =  !cardSelected.find(it => it.tag === position.tag )
                    if(notExist){
                        cardSelected.push(position)
                    }

                    cardSelected.map((value) => {
                        dataTags[value.tag] = value;
                    })
                }
            });
        }


        let typeOption = this.state.typeOption
        let mandatoryOption = this.state.mandatoryOption
        let hidePrintOutOption = this.state.hidePrintOutOption

        return (
            /*xl-lg-sm*/
            <Modal
                style={{ minWidth: '80%' }}
                size="xl"
                centered={true}
                isOpen={this.state.modal}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    {label.FormInputConfiguration}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">{label.FormPage}</Label>
                                <ItemOption
                                    type="text"
                                    name="name"
                                    default={lane.id}
                                    objects={pageOptions}
                                    hideLabel={true}
                                    hideOptionLabel={true}
                                    callback={(e) => {

                                        let { original, form, position } = this.state
                                        let diffLane = false;
                                        lanes.forEach((lane1, laneIndex) => {
                                            if (lane1.id == e.id) {
                                                diffLane = true;
                                                // form.tag = (laneIndex + 1) + "." + (lane1.cards.length + 1)
                                                position.tag = (laneIndex + 1) + "." + (lane1.cards.length + 1)
                                            } else if (form.lane && form.lane.id == e.id) {
                                                form.tag = this.state.previousTag
                                            }
                                        })
                                        this.setState({
                                            diffLane: diffLane,
                                            lane: e,
                                            form: form,
                                            position: position
                                        })
                                    }}
                                    placeholder={label.EnterFormPage}
                                />
                            </FormGroup>
                        </Col>

                            <Col>
                                <FormGroup>
                                    <Label for="name">{label.Tag}</Label>
                                    <Input
                                        type="select"
                                        id="input1-group2"
                                        name="input1-group2"
                                        value={position.tag ? position.tag : ""}
                                        onChange={(e) => {
                                            if (position) {
                                                this.setState({
                                                    position: dataTags[e.target.value],
                                                    board: board,
                                                    changePosition: true
                                                })
                                            }
                                        }}

                                    >
                                        {cardSelected && Object.keys(cardSelected).length > 0 ?
                                            cardSelected.map((item) => (
                                                <option value={item.tag}>{item.tag}</option>
                                            )) :
                                            (
                                                <option value={""}></option>
                                            )
                                        }

                                    </Input>
                                </FormGroup>
                            </Col>
                        <Col>
                            <FormGroup>
                                <Label for="name">{label.Type}</Label>
                                <ItemOption
                                    type="text"
                                    name="name"
                                    default={typeOption.id}
                                    objects={this.typeOptions}
                                    hideLabel={true}
                                    hideOptionLabel={true}
                                    title={label.SelectType}
                                    callback={(typeOption) => {
                                        this.setState({
                                            typeOption: typeOption
                                        })
                                    }}
                                    placeholder={label.EnterFormType}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="name">{label.Mandatory} ?</Label>
                                <ItemOption
                                    type="text"
                                    name="name"
                                    default={mandatoryOption.id}
                                    objects={this.mandatoryOptions}
                                    hideLabel={true}
                                    hideOptionLabel={true}
                                    callback={(e) => {
                                        mandatoryOption = e
                                        this.setState({
                                            mandatoryOption: mandatoryOption
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="name">{label.HidePrintout} ?</Label>
                                <ItemOption
                                    type="text"
                                    name="name"
                                    default={hidePrintOutOption.id}
                                    objects={this.hidePrintOutOptions}
                                    hideLabel={true}
                                    hideOptionLabel={true}
                                    callback={(e) => {
                                        hidePrintOutOption = e
                                        this.setState({
                                            hidePrintOutOption: hidePrintOutOption
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={8}>
                            <FormGroup>
                                <Label for="name">{label.Title}</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={form.title ? form.title : ""}
                                    onChange={(e) => {
                                        form.title = e.target.value
                                        validator.title = ""
                                        this.setState({
                                            form: form,
                                            validator: validator
                                        })
                                    }}
                                    placeholder={label.EnterFormTitle}
                                />
                                <WarningLabel show={validator.title} message={validator.title} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="name">{label.Placeholder}</Label>
                                <Input
                                    type="textarea"
                                    name="name"
                                    rows={5}
                                    value={form.placeHolder ? form.placeHolder : ""}
                                    onChange={(e) => {
                                        form.placeHolder = e.target.value
                                        validator.placeHolder = ""
                                        this.setState({
                                            form: form,
                                            validator: validator
                                        })
                                    }}
                                    placeholder={label.EnterPlaceholder}
                                />
                                <WarningLabel show={validator.placeHolder} message={validator.placeHolder} />
                            </FormGroup>
                        </Col>
                    </Row>
                    {typeOption.name === FormType.DROPDOWN && (null)}
                    {
                        typeOption.name === FormType.DROPDOWN_DATA_API && (
                            this.renderFormApi()
                        )
                    }
                    {
                        typeOption.name === FormType.POSITIVE_NEGATIVE && (
                            this.renderFormPositiveNegative()
                        )
                    }
                    {
                        (typeOption.name == FormType.TEXT) && (
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="name">{label.UnallowedCharacters}</Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={form.unallowedCharacters ? form.unallowedCharacters : ""}
                                            onChange={(e) => {
                                                form.unallowedCharacters = e.target.value
                                                this.setState({
                                                    form: form
                                                })
                                            }}
                                            placeholder={label.EnterUnallowedCharacters}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="name">{label.MaxLength}</Label>
                                        <Input
                                            type="number"
                                            name="name"
                                            value={form.maxLength ? form.maxLength : ""}
                                            onChange={(e) => {
                                                form.maxLength = e.target.value
                                                this.setState({
                                                    form: form
                                                })
                                            }}
                                            placeholder={label.EnterMaxLength}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="name">{label.LineCount}</Label>
                                        <Input
                                            type="number"
                                            name="name"
                                            value={form.line ? form.line : ""}
                                            onChange={(e) => {
                                                form.line = e.target.value
                                                this.setState({
                                                    form: form
                                                })
                                            }}
                                            placeholder={label.EnterLineCount}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        )
                    }
                    {this.renderSubField()}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={e => {
                        this.onClickOk()
                    }}>
                        {label.Ok}
                    </Button>{' '}
                    <Button color="secondary" onClick={e => (this.close())}>
                        {label.Cancel}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

FormModal.propTypes = {
    showing: PropTypes.bool.required,
    onAdd: PropTypes.func.required,
    close: PropTypes.func.required,
    lanes: PropTypes.array,
    lane: PropTypes.any,
    form: PropTypes.any
}
