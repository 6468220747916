import Page from 'components/Page';
import {NumberWidget} from 'components/Widget';
import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import {getColor} from 'utils/colors';
import BasePage from './BasePage';
import Global, {_PAID} from '../utils/Global';
import {MdRefresh} from 'react-icons/md/index';
import DashboardInspectionCard from '../components/tab/dashboard/DashboardInspectionCard';
import DashboardCategoriyAnalytic from './../components/tab/dashboard/DashboardCategoryAnalytic';
import SockJsClient from 'react-stomp';
import {AdminDashboard} from '../model/model';
import label from '../message/Label'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const today = new Date();

let transactionCardRef =  React.createRef()
let categoryCardRef =  React.createRef()
let transactionItemCardRef =  React.createRef()
let transactionAnalyticCardRef =  React.createRef()

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

class MainDashboardPage extends BasePage {

  constructor(props) {
    super(props);
    let state = this.state
    state.mounted = false
    state.adminDashboard = new AdminDashboard()
    state.transactionStatusId = _PAID
    this.setState(state)
  }


  componentDidMount() {
    // this is needed, because InfiniteCalendar forces window scroll
    window.scrollTo(0, 0);
    if(!this.state.mounted){
      this.setState({
        mounted : true
      }, () => {
        this.fetchAdminDashboard(true)
      })
    }
  }


  fetchAdminDashboard=(progressing)=>{
    this.get(Global.API.ADMIN_DASHBOARD, null, null, response=>{
      if(response.code===200){
        this.setState({
          adminDashboard:response.data
        })
      }else{
        this.errorToast(response.message)
      }
    }, progressing, true)
  }

  refreshDashboard=(message)=>{
    this.fetchAdminDashboard(false, message)
    if(transactionCardRef){
      transactionCardRef.current.refreshDashboard()
    }
    if(categoryCardRef){
      categoryCardRef.current.fetchCategoryAnalytic()
    }
    if(transactionItemCardRef){
      transactionItemCardRef.current.fetchTransactionAnalytic()
    }
    if(transactionAnalyticCardRef){
      transactionAnalyticCardRef.current.fetchTransactionAnalytic()
    }
  }

  render() {

    const primaryColor = getColor('primary');
    const secondaryColor = getColor('secondary');
    let adminDashboard = this.state.adminDashboard
    let transactionStatusId = this.state.transactionStatusId
    return (
      <Page
        className="DashboardPage"
        title={label.Dashboard}
        breadcrumbs={[{ name: label.Dashboard, active: true }]}
      >
        {super.render()}
        <Row>
          <Col>
            <Button className="float-right" onClick={event=>{
              this.fetchAdminDashboard(true)
            }}>{label.Refresh} <MdRefresh/></Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{textAlign:'center'}}>
              <Carousel
                  autoPlay={true}
                  infinite={true}
                  partialVisible={true}
                  autoPlaySpeed={3000}
                  style={{maxWidth:'10%'}}
                  responsive={responsive}
                  itemClass="carousel-item-padding-40-px">
                <NumberWidget
                    style={{margin:'12px'}}
                    title={label.TotalInspection}
                    subtitle={label.PreviousMonth+" : "+adminDashboard.previousMonthInspection}
                    number={adminDashboard.totalInspection&&adminDashboard.totalInspection.toString()}
                    color="secondary"
                    progress={{
                      value: Math.ceil(adminDashboard.thisMonthInspectionPercent),
                      label: label.ThisMonth+' : '+adminDashboard.thisMonthInspection,
                    }}
                />
                <NumberWidget
                    style={{margin:'12px'}}
                    title={label.Taken}
                    subtitle={label.PreviousMonth+" : "+adminDashboard.previousMonthTaken}
                    number={adminDashboard.totalTaken&&adminDashboard.totalTaken.toString()}
                    color="secondary"
                    progress={{
                      value: Math.ceil(adminDashboard.thisMonthTakenPercent),
                      label: label.ThisMonth+' : '+adminDashboard.thisMonthTaken,
                    }}
                />
                <NumberWidget
                    style={{margin:'12px'}}
                    title={label.PassInspection}
                    subtitle={label.PreviousMonth+" : "+adminDashboard.previousMonthPass}
                    number={adminDashboard.totalPass&&adminDashboard.totalPass.toString()}
                    color="secondary"
                    progress={{
                      value: Math.ceil(adminDashboard.thisMonthPassPercent),
                      label: label.ThisMonth+' : '+adminDashboard.thisMonthPass,
                    }}
                />
                <NumberWidget
                    style={{margin:'12px'}}
                    title={label.NotPassInspection}
                    subtitle={label.PreviousMonth+" : "+adminDashboard.previousMonthNotPass}
                    number={adminDashboard.totalNotPass&&adminDashboard.totalNotPass.toString()}
                    color="secondary"
                    progress={{
                      value: Math.ceil(adminDashboard.thisMonthNotPassPercent),
                      label: label.ThisMonth+' : '+adminDashboard.thisMonthNotPass,
                    }}
                />
                <NumberWidget
                    style={{margin:'12px'}}
                    title={label.PostponedInspection}
                    subtitle={label.PreviousMonth+" : "+adminDashboard.previousMonthPostponed}
                    number={adminDashboard.totalPostponed}
                    color="secondary"
                    progress={{
                      value: Math.ceil(adminDashboard.thisMonthPostponedPercent),
                      label: label.ThisMonth+' : '+adminDashboard.thisMonthPostponed,
                    }}
                />
              </Carousel>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <DashboardInspectionCard ref={transactionCardRef} {...this.props}/>
          </Col>
          <Col md={5}>
            <DashboardCategoriyAnalytic ref={categoryCardRef} {...this.props}/>
          </Col>
        </Row>
        <SockJsClient
            url={Global.BASE_URL}
            topics={[Global.API.LISTEN_UPDATE_MODEL]}
            getRetryInterval={5}
            heartbeat={60000}
            onMessage={(message) => {
              this.refreshDashboard(message)
            }}
            ref={ (client) => { this.clientRef = client }} />
        <SockJsClient
            url={Global.BASE_URL}
            topics={[Global.API.LISTEN_UPDATE_INSPECTIONS]}
            getRetryInterval={5}
            heartbeat={60000}
            onMessage={(message) => {
              this.refreshDashboard(message)
            }}
            ref={ (client) => { this.clientRef = client }} />
      </Page>
    );
  }
}
export default Object.assign(MainDashboardPage, {ENDPOINT : "/"})
