import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/FormGroup';
import Label from 'reactstrap/es/Label';
import Input from 'reactstrap/es/Input';
import label from '../../message/Label'

export default class TrackingDialog extends React.Component{

    state={

    }

    constructor(props){
        super(props);
        let state = this.state
        state.modal = this.props.showing
        state.modal_backdrop = false
        state.modal_nested_parent = false
        state.modal_nested = false
        state.backdrop = false
        state.title = this.props.title
        state.message = this.props.message
        state.waybill = this.props.waybill
        this.setState(state)
        this.toggle = this.toggle.bind(this)
        this.buttonClick = this.buttonClick.bind(this)
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            this.setState({
                modal:props.showing,
                message : props.message,
                waybill:props.waybill
            })
        }
    }

    toggle(modalType){
        if (!modalType) {
            this.setState({
                modal: !this.state.modal,
                [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
            });
        }
    }

    buttonClick (e, button){
        e.preventDefault()
        this.toggle('backdrop')
        if(button===label.Ok){
            if(this.props.okCallback!=null){
                this.props.okCallback()
            }
        }else{
            if(this.props.cancelCallback!=null){
                this.props.cancelCallback()
            }
        }
    }

    render(){
        let waybill = this.state.waybill?this.state.waybill:{}
        let details = waybill.details?waybill.details:{}
        let deliveryStatus = waybill.delivery_status?waybill.delivery_status:{}
        let manifest = waybill.manifest?waybill.manifest:[]

        return (
            <Modal
                isOpen={this.state.modal}
                toggle={this.toggle('backdrop')}
                size="lg"
                backdrop={false}>
                <ModalHeader toggle={this.toggle('backdrop')}>
                    Tracking :&nbsp;{this.props.courier}&nbsp;#&nbsp;{this.props.courierservice}&nbsp;#&nbsp;{this.props.resicode}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="status">{label.Status}</Label>
                                <Input
                                    type="text"
                                    name="status"
                                    value={deliveryStatus.status}
                                    readOnly={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="receiver">Receiver</Label>
                                <Input
                                    type="text"
                                    name="receiver"
                                    value={details.receiver_name}
                                    readOnly={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="origin">Origin</Label>
                                <Input
                                    type="text"
                                    name="origin"
                                    value={details.origin}
                                    readOnly={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="destination">Destination</Label>
                                <Input
                                    type="text"
                                    name="destination"
                                    value={details.destination}
                                    readOnly={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table responsive hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Datetime</th>
                                    <th>{label.Description}</th>
                                    <th>City</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (manifest && manifest.length>0)&&(
                                        manifest.map((item, index)=>(
                                            <tr>
                                                <td scope="row">{++index}</td>
                                                <td>{item.manifest_date+" "+item.manifest_time}</td>
                                                <td>{item.manifest_description}</td>
                                                <td>{item.city_name}</td>
                                            </tr>
                                        )))
                                }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={e=>(this.buttonClick(e, label.Ok))}>
                        {label.Ok}
                    </Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

TrackingDialog.propTypes = {
    showing:PropTypes.bool.isRequired,
    waybill:PropTypes.object,
    okCallback:PropTypes.func,
    courier:PropTypes.string,
    courierservice:PropTypes.string,
    resicode:PropTypes.string

}
