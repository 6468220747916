import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Input} from 'reactstrap';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import label from '../../message/Label'
import {isEmpty} from "../../utils/Utilities";

const { htmlToText } = require('html-to-text');


/*= (message, okCallback, cancelCallback) =>*/
export default class SerialComponentInfoModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        let inspection = this.props.inspection?this.props.inspection:{}
        inspection = inspection?inspection:{}
        let components = []
        if(!isEmpty(inspection.serialComponent)){
            let serialComponent = inspection.serialComponent?inspection.serialComponent:{}
            components = serialComponent.components?serialComponent.components:[]
        }else{
            let model = inspection.model?inspection.model:{}
            components = model.components?model.components:[]
        }
        state.inspection = inspection
        state.components = components
        state.showing = this.props.showing

        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            let inspection = props.inspection?props.inspection:{}
            inspection = inspection?inspection:{}
            let components = []
            if(!isEmpty(inspection.serialComponent)){
                let serialComponent = inspection.serialComponent?inspection.serialComponent:{}
                components = serialComponent.components?serialComponent.components:[]
            }else{
                let model = inspection.model?inspection.model:{}
                components = model.components?model.components:[]
            }

            this.setState({
                inspection : inspection,
                components : components,
                showing:props.showing,
            })
        }
    }



    render(){
        let {showing, inspection, components} = this.state
        let model = inspection.model?inspection.model:{}

        return (
            /*xl-lg-sm*/
            <Modal
                autoFocus={false}
                size="xl"
                centered={true}
                isOpen={showing}
                backdrop={true}
                zIndex="1">
                {super.render()}
                <ModalHeader>
                    {label.SerialComponentList}
                </ModalHeader>
                <ModalBody>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.Name}</th>
                            <th>{label.Value}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            components.map((item, index)=>(
                                <>
                                    <tr key={item.id} style={{borderBottom:(!isEmpty(item.description)?'hidden':'1px')}}>
                                        <td width={'10%'}>
                                            {(++index)}
                                        </td>
                                        <td width={'30%'}>
                                            {item.label}
                                        </td>
                                        <td width={'60%'}>
                                            <Input
                                                readOnly
                                                type="text"
                                                name="name"
                                                value={item.value}
                                                onChange={(e) =>{
                                                    item.value = e.target.value
                                                    item.error = false
                                                    this.setState({
                                                        components
                                                    })
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    {!isEmpty(item.description)&&(
                                        <tr>
                                            <td colSpan={3} style={{padding:'0rem'}}>
                                        <span style={{fontStyle:'italic', fontSize:'0.85rem', color:'blue'}}>
                                                *{label.Description} : {item.description}
                                            </span>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))
                        }
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="warning"
                        onClick={async (e)=>{
                            e.preventDefault()
                            this.props.onClose()
                        }}>
                        {label.Close}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

SerialComponentInfoModal.propTypes = {
    inspection:PropTypes.object.isRequired,
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
}
