import React from 'react';
import {CardFooter} from 'reactstrap/es';
import {Button, ButtonGroup, ButtonToolbar} from 'reactstrap';
import PropTypes from 'prop-types';
import Global from '../utils/Global';
import {navigatePage} from '../utils/Utilities';


export default class Pagination extends React.Component{

    state = {}
    constructor(props) {
        super(props);
        let state = this.state
        state.pageCount = this.props.pageCount
        state.onSelect = this.props.onSelect
        state.currentPage = this.props.currentPage?this.props.currentPage:1
        state.currentPaging = state.currentPage>Global.LIMIT_PAGING_BUTTON?((state.currentPage/Global.LIMIT_PAGING_BUTTON)+1):1 ;
        this.setState(state)
    }

    componentDidMount() {
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            let currentPage = props.currentPage?props.currentPage:1
            this.setState({
                pageCount : props.pageCount,
                onSelect : props.onSelect,
                currentPage : currentPage,
                currentPaging : currentPage>Global.LIMIT_PAGING_BUTTON?((currentPage/Global.LIMIT_PAGING_BUTTON)+1):1
            })
        }
    }

    render() {
        let pageCount = this.state.pageCount
        let currentPage = this.state.currentPage
        let onSelect = this.state.onSelect
        let pages = new Array(pageCount)
        let currentPaging = this.state.currentPaging
        for (var i = 0; i < pageCount; i++) {
            pages[i] = i + 1
        }
        return (
            <CardFooter>
                <ButtonToolbar className="float-right">
                    <ButtonGroup>
                        <Button color="none">Page :&nbsp;&nbsp;</Button>
                    </ButtonGroup>
                    {
                        currentPage>1&&(
                            <ButtonGroup className="mr-2">
                                <Button onClick={event => {
                                    event.preventDefault()
                                    let currentPaging = this.state.currentPaging
                                    if((currentPaging*Global.LIMIT_PAGING_BUTTON)>parseInt(this.state.currentPage)-1){
                                        currentPaging = currentPaging-1
                                    }

                                    this.setState({
                                        currentPage : parseInt(this.state.currentPage)-1,
                                        currentPaging :currentPaging
                                    }, () => {
                                        if(this.props.callback!=null){
                                            this.props.callback(this.state.currentPage)
                                        }else{
                                            navigatePage(this.props, this.state.currentPage)
                                        }
                                    })
                                }}>{"<"}</Button>
                            </ButtonGroup>
                        )
                    }
                    {
                        (currentPaging>1)&&(
                            <ButtonGroup className="mr-2">
                                <Button onClick={event => {
                                    event.preventDefault()
                                    this.setState({
                                        currentPaging:this.state.currentPaging-1
                                    })
                                }}>{"<<"}</Button>
                            </ButtonGroup>
                        )
                    }

                    <ButtonGroup className="mr-2">
                        {
                            pages.map((item, index)=>(
                                (((Global.LIMIT_PAGING_BUTTON*currentPaging))>index && (Global.LIMIT_PAGING_BUTTON*(currentPaging-1))<=index)&&(
                                    <Button color={item==(currentPage)?"primary":"secondary"} key={index} onClick={e=>{
                                        e.preventDefault()
                                        if(item!=currentPage) {
                                            this.setState({
                                                currentPage: item
                                            }, () => {
                                                if(this.props.callback!=null){
                                                    this.props.callback(this.state.currentPage)
                                                }else{
                                                    navigatePage(this.props, this.state.currentPage)
                                                }
                                            })
                                        }
                                    }}>{item}</Button>
                                )
                            ))
                        }
                    </ButtonGroup>
                    {
                        (pages.length>(Global.LIMIT_PAGING_BUTTON*currentPaging))&&(
                            <ButtonGroup className="mr-2">
                                <Button onClick={event => {
                                    event.preventDefault()
                                    this.setState({
                                        currentPaging:this.state.currentPaging+1
                                    })
                                }}>{">>"}</Button>
                            </ButtonGroup>
                        )
                    }
                    {
                        (currentPage<pageCount)&&(
                            <ButtonGroup className="mr-2">
                                <Button onClick={event => {
                                    event.preventDefault()
                                    let currentPaging = this.state.currentPaging
                                    if((currentPaging*Global.LIMIT_PAGING_BUTTON)<parseInt(this.state.currentPage)+1){
                                        currentPaging = currentPaging+1
                                    }
                                    this.setState({
                                        currentPage : parseInt(this.state.currentPage)+1,
                                        currentPaging :currentPaging
                                    }, () => {
                                        if(this.props.callback!=null){
                                            this.props.callback(this.state.currentPage)
                                        }else{
                                            // let {pathname} = this.props.location
                                            // let currentUrlParams = new URLSearchParams(window.location.search);
                                            // currentUrlParams.set('page', this.state.currentPage);
                                            // this.props.history.push(pathname.includes("?")?(pathname+currentUrlParams.toString()):(pathname+"?"+currentUrlParams.toString()))
                                            navigatePage(this.props, this.state.currentPage)
                                        }
                                    })
                                }}>{">"}</Button>
                            </ButtonGroup>
                        )
                    }
                </ButtonToolbar>
            </CardFooter>
        )
    }

    paginationButton = () =>{
        let button = []
        for (let i = 0; i < this.state.pageCount; i++) {
            button.push(<Button onClick={e=>{
                e.preventDefault()
                this.setState({
                    page:--i
                }, () => {
                    // this.refreshProduct(this.state.ascending, this.state.sortir)
                })

            }}>{++i}</Button>)

        }
        return button
    }

}

Pagination.propTypes = {
    pageCount : PropTypes.number,
    onSelect : PropTypes.func,
    currentPage : PropTypes.number,
    callback : PropTypes.func
}
