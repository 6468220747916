import axios from 'axios';
import Global from './Global';
import {getData, removeData, storeData} from './StorageUtil';
import Cookies from "js-cookie"

import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load()

const kitchen = axios.create({
    baseURL:Global.BASE_URL,
    timeout:1000*60*60,
    maxContentLength: 9999999999999999999999,
    maxBodyLength: 9999999999999999999999
})

kitchen.interceptors.request.use(async function (config) {
    if(Global.DEBUG){
        console.log({"request":config})
    }
    let authorization = getData(Global.AUTHORIZATION)
    if(authorization){
        config.headers['Authorization'] = authorization;
    }else{
        config.headers = {}
    }
    let visitorId = Cookies.get('visitorId');
    if(!visitorId){ // Tidak ada cookie? remove cookie dan generate baru
        Cookies.remove("visitorId");

        const fp = await fpPromise
        const result = await fp.get()
        visitorId = result.visitorId
        Cookies.set("visitorId", visitorId, { expires: 365 })
    }

    // const visitorId = result.visitorId
    config.headers['DeviceId'] = visitorId;

    config.headers['Platform'] = "Web-Apps";
    config.headers['Accept-Language'] = Global.DEFAULT_LANGUAGE;
    return config;
}, function (error) {
    return Promise.reject(error);
});

kitchen.interceptors.response.use(function (response) {
    if(Global.DEBUG){
        console.log({"response":response})
    }
    if(response.headers && response.headers.authorization){
        if(response.headers.authorization!=''){
            storeData(Global.AUTHORIZATION, response.headers.authorization)
        }else{
            removeData(Global.AUTHORIZATION)
        }
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default kitchen
