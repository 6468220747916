import * as React from 'react';
import Page from './../components/Page';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import BasePage from './BasePage';
import Board from '@lourenci/react-kanban';
import FormLaneModal from '../components/modal/FormLaneModal';
import FormModal from '../components/modal/FormModal';
import FormCard from '../components/Card/FormCard';
import { GroupForm } from '../model/formbuilder/GroupForm';
import Input from 'reactstrap/es/Input';
import WarningLabel from '../components/Widget/WarningLabel';
import Global, { DD_MMMM_YYYY_HH_MM_SS } from '../utils/Global';
import * as queryString from 'query-string';
import { cloneObject, parseDate, isEmpty } from '../utils/Utilities';
import label from '../message/Label'
import { FormLane } from "../model/formbuilder/FormLane";
import { MdAdd, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { FaCopy } from 'react-icons/fa';
import HorizontalScroll from 'react-horizontal-scrolling'

const { htmlToText } = require('html-to-text');

const CircularJSON = require('circular-json')

class FormBoard {
    lanes = []
}

const tableTypes = ['', 'bordered', 'striped', 'hover'];
class FormBuilderDetailPage extends BasePage {
    board = new FormBoard()

    validator = {
        title: ""
    }

    constructor(props) {
        super(props);
        let groupForm = new GroupForm();
        this.state = {
            categories: [],
            category: {},
            headings: [],
            heading: {},
            modalAddConfirm: false,
            modalDeleteConfirm: false,
            formLane: new FormLane(),
            formLaneModal: false,
            formModal: false,
            ascending: true,
            sortir: 'created',
            board: this.board,
            groupForm: groupForm,
            groupFormId: queryString.parse(this.props.query).id,
            validator: this.validator,
            formEdit: {},
        }
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.state.groupFormId) {
            this.fetchDetail()
        }
    }

    fetchDetail() {
        let board = this.state.board
        this.get(Global.API.GROUP_FORM + '/' + this.state.groupFormId, null, null, (res) => {
            if (res.code === 200) {
                board.lanes = res.data.formLanes
                board.lanes.forEach((item, index) => {
                    item.cards.forEach((value, index1) => {
                        value.lane = cloneObject(item)
                        value.lane.cards = []
                    })
                })
                this.setState({
                    groupForm: res.data,
                    board: board
                })
            }
        }, true, false)
    }

    reorderFormCard = (board) => {
        board.lanes.forEach((lane, laneIndex) => {
            lane.cards.forEach((card, cardIndex) => {
                card.tag = (laneIndex + 1) + "." + (cardIndex + 1)
            })
        })
        this.setState({
            board: board
        })
    }



    onClickSave() {
        let groupForm = this.state.groupForm
        let newData = !groupForm.id;
        let validator = this.state.validator
        let board = this.state.board;

        
        let validate = true;

        if (!groupForm.name) {
            validator.title = label.PleaseEnterGroupForm
            this.setState({
                validator: validator
            })
            return;
        }
        if (!groupForm.documentNumber) {
            validator.documentNumber = label.PleaseEnterDocumentNumber
            this.setState({
                validator: validator
            })
            return;
        }
        if (!board.lanes || board.lanes.length === 0) {
            validate = false
            this.showDialog(label.Warning, label.PleaseAddAMinimumSingleForm)
            return;
        }
        if ( board  && board.lanes && Object.keys(board.lanes).length > 0) {
            board.lanes.forEach(value => {
                
                if (!value || !value.cards || Object.keys(value.cards).length <= 0) {
                    validate = false
                    this.showDialog(label.Warning, label.PleaseAddAMinimumSingleForm)
                    return
                }
            })
        }

         if(!validate){
            return
         }


        groupForm.formLanes = board.lanes
        groupForm.formLanes.forEach((value, index) => {
            value.id = index + 1
            value.cards.forEach((value1, index1) => {
                value1.id = index1 + 1
            })
        })
        groupForm = CircularJSON.stringify(groupForm)
        groupForm = JSON.parse(groupForm)
        this.post(Global.API.GROUP_FORM_SAVE, groupForm, null, (res) => {
            if (res.code === 200) {
                this.successToast(label.SuccessUpdatingData)
                let groupForm = res.data
                if (newData) {
                    this.props.history.goBack()
                    window.location.href = FormBuilderDetailPage.ENDPOINT + "?id=" + groupForm.id
                } else {
                    board = new FormBoard()
                    this.setState({
                        groupFormId: groupForm.id
                    }, () => {
                        // this.fetchDetail()
                        setTimeout(() => window.location.reload(), 1500)
                      
                    })
                }
            }
        }, true, true)
    }

    render() {
        let { board,
            formLaneModal,
            formLane,
            formModal,
            validator,
            groupForm
        } = this.state
        return (
            <Page
                title={label.FormBuilder}
                breadcrumbs={[{ name: label.FormBuilder, active: true }]}
                className="TablePage">
                {super.render()}
                <FormLaneModal
                    formLane={formLane}
                    showing={formLaneModal}
                    onAdd={(formLane) => {
                        let lanes = board.lanes
                        let indexToReplace = -1;
                        lanes.forEach((item, index) => {
                            if (item.id == formLane.id) {
                                indexToReplace = index;
                            }
                        })
                        if (indexToReplace > -1) {
                            lanes[indexToReplace] = formLane;
                        } else {
                            lanes.push(formLane)
                        }
                        board.lanes = lanes
                        this.setState({
                            board: board,
                            formLaneModal: false,
                        }, () => {
                            this.reorderFormCard(board)
                        })
                    }} close={() => {
                        this.setState({
                            formLaneModal: false,
                        })
                    }} />
                <FormModal
                    showing={formModal}
                    groupForm={this.state.groupForm ? this.state.groupForm : null}
                    board={board}
                    lanes={cloneObject(board.lanes)}
                    lane={cloneObject(this.state.formEdit ? this.state.formEdit.lane : null)}
                    form={this.state.formEdit}
                    onAdd={(form, lane, newBoard) => {
                        if (newBoard) {
                            this.reorderFormCard(newBoard)
                        } else {
                            let laneIndex = -1;
                            let laneId = -1;
                            board.lanes.forEach((value, index) => {
                                if (value.id === lane.id) {
                                    laneIndex = index;
                                    laneId = value.id
                                }
                            })
                            if (laneIndex > -1) {
                                let lanes = board.lanes
                                if (form.lane && form.lane.id !== laneId) {
                                    board.lanes.forEach((item, index) => {
                                        if (form.lane.id == item.id) {
                                            item.cards.forEach((value, index1) => {
                                                if (value.id == form.id) {
                                                    item.cards.splice(index1, 1)
                                                }
                                            })
                                        }
                                    })
                                    board.lanes[laneIndex].cards.push(form)
                                } else {
                                    // still on previous lane position
                                    let indexToReplace = -1;
                                    board.lanes[laneIndex].cards.forEach((value, index) => {
                                        if (value.id === form.id) {
                                            indexToReplace = index
                                        }
                                    })
                                    if (indexToReplace > -1) {
                                        board.lanes[laneIndex].cards[indexToReplace] = form
                                    } else {
                                        board.lanes[laneIndex].cards.push(form)
                                    }
                                }
                                form.lane = lane
                                board.lanes = lanes
                                this.reorderFormCard(board)
                                this.setState({
                                    formModal: false,
                                })
                            }
                        }

                    }}
                    close={() => {
                        this.setState({
                            formModal: false,
                        })
                    }}
                />
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader>
                                <strong>{label.FormBuilder}</strong>
                            </CardHeader>
                            <CardBody >
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="name">{label.FormName}</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                value={groupForm ? groupForm.name : ""}
                                                onChange={(e) => {
                                                    groupForm.name = e.target.value
                                                    validator.title = ""
                                                    this.setState({
                                                        groupForm: groupForm,
                                                        validator: validator
                                                    })
                                                }}
                                                placeholder={label.EnterFormName}
                                            />
                                            <WarningLabel message={validator.title} show={!isEmpty(validator.title)} />  
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="documentNumber">{label.DocumentNumber}</Label>
                                            <Input
                                                type="text"
                                                name="documentNumber"
                                                value={groupForm ? groupForm.documentNumber : ""}
                                                onChange={(e) => {
                                                    groupForm.documentNumber = e.target.value
                                                    validator.documentNumber = ""
                                                    this.setState({
                                                        groupForm: groupForm,
                                                        validator: validator
                                                    })
                                                }}
                                                placeholder={label.EnterDocumentNumber}
                                            />
                                            <WarningLabel message={validator.documentNumber}  show={!isEmpty(validator.documentNumber)}/>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="name">{label.Created}</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                value={groupForm ? parseDate(groupForm.created, DD_MMMM_YYYY_HH_MM_SS) : "-"}
                                                onChange={(e) => {
                                                    groupForm.created = e.target.value
                                                    this.setState({
                                                        groupForm: groupForm
                                                    })
                                                }}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="name">{label.Updated}</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                value={groupForm ? parseDate(groupForm.updated, DD_MMMM_YYYY_HH_MM_SS) : "-"}
                                                onChange={(e) => {
                                                    groupForm.updated = e.target.value
                                                    this.setState({
                                                        groupForm: groupForm
                                                    })
                                                }}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Button style={{ marginRight: '10px' }} color='primary' onClick={e => {
                                    this.setState({
                                        formLane: new FormLane(),
                                        formLaneModal: true
                                    })
                                }}>{label.AddFormPage}&nbsp;&nbsp;&nbsp;<MdAdd /></Button>
                                {
                                    (board.lanes.length > 0) && (
                                        <Button color='purple' style={{ marginRight: '10px' }} onClick={e => {
                                            this.setState({
                                                formEdit: null,
                                                formModal: true
                                            })
                                        }}>{label.AddFormCard}&nbsp;&nbsp;&nbsp;<MdAdd /></Button>
                                    )
                                }
                                <Button style={{ marginRight: '10px' }} onClick={(e) => this.onClickSave()}>{label.SaveForm}&nbsp;&nbsp;&nbsp;<MdSave /></Button>
                            </CardHeader>

                            <CardBody className="body-board">
                                <Board
                                    disableCardDrag
                                    initialBoard={board}
                                    children={board}
                                    allowRemoveLane
                                    allowRenameLane
                                    allowRemoveCard
                                    allowAddLane
                                    onLaneRemove={(board, lane) => {
                                        this.reorderFormCard(board)
                                    }}
                                    onCardRemove={(board, lane, card) => {
                                        this.reorderFormCard(board)
                                    }}
                                    onLaneRename={(board, lane) => {
                                        this.reorderFormCard(board)
                                    }}
                                    onCardDragEnd={(board, source, destination) => {
                                        this.reorderFormCard(board)
                                    }}
                                    onLaneDragEnd={(board, source, destination) => {
                                        this.reorderFormCard(board)
                                    }}
                                    renderLaneHeader={(lane, { removeLane, renameLane, addCard }) => (
                                        <Card style={{ border: '2px #4530E3 solid', marginBottom: '24px' }}>
                                            <CardHeader>
                                                <strong>{lane.title}</strong>
                                                <Button color={"primary"} style={{ float: 'right' }} onClick={(e) => {
                                                    this.setState({
                                                        formLane: cloneObject(lane),
                                                        formLaneModal: true,
                                                    })
                                                }}>
                                                    <MdEdit />
                                                </Button>
                                                &nbsp;
                                                <Button
                                                    color={"warning"}
                                                    style={{ float: 'right', marginRight: '8px' }}
                                                    onClick={() => {
                                                        let formLane = cloneObject(lane)
                                                        formLane.id = undefined
                                                        this.setState({
                                                            formLane: formLane,
                                                            formLaneModal: true
                                                        })
                                                    }}>
                                                    <FaCopy />
                                                </Button>
                                                &nbsp;
                                                <Button
                                                    color={"danger"}
                                                    style={{ float: 'right', marginRight: '8px' }}
                                                    onClick={() => {
                                                        this.openConfirmDialog(label.Confirmation, label.DoYouWantToRemoveThisLane, () => {
                                                            removeLane()
                                                        })
                                                    }}>
                                                    <MdDelete />
                                                </Button>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Description}</Label>
                                                            <Input
                                                                type="textarea"
                                                                name="description"
                                                                rows={3}
                                                                value={htmlToText(lane.description, { wordWrap: 50 })}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    )}
                                    renderCard={(card, cardBag) => (
                                        <FormCard
                                            form={card}
                                            board={board}
                                            cardBag={cardBag}
                                            lanes={board.lanes}
                                            onNewFormClone={(board) => this.reorderFormCard(board)}
                                            editCard={(form) => {
                                                this.setState({
                                                    formEdit: cloneObject(form),
                                                    formModal: true
                                                })
                                            }} />
                                    )}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
        );
    }
}

export default Object.assign(FormBuilderDetailPage, { ENDPOINT: "/formBuilderDetail" })