import React from 'react';
import PropTypes from 'utils/propTypes';
import classNames from 'classnames';
import {Card, CardBody, CardSubtitle, CardText, CardTitle} from 'reactstrap';
import Avatar from '../Avatar';

const UserCard = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  text,
  children,
  className,
  onError,
  ...restProps
}) => {
  const classes = classNames('bg-gradient-theme', className);

  return (
    <Card inverse className={classes} {...restProps}>
      <CardBody className="d-flex justify-content-center align-items-center flex-column">
        <Avatar src={avatar} size={avatarSize} className="mb-2" onClick={event=>{
          event.preventDefault()
          if(restProps.onClickAvatar){
            restProps.onClickAvatar(event)
          }
        }} onError={onError}/>
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
        <CardText>
          <small>{text}</small>
        </CardText>
      </CardBody>
      {children}
    </Card>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  onClickAvatar :PropTypes.func,
  onError:PropTypes.func,
};

UserCard.defaultProps = {
  avatarSize: 80,
};

export default UserCard;
