import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {AuthConsumer} from '../../utils/AuthContext';


const LayoutRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <AuthConsumer>
        {({isAuth}) =>(
            <Route
                {...rest}
                render={props => isAuth ?
                    <Redirect to="/"/>
                    :
                    <Layout>
                        <Component {...props} query={props.location.search} />
                    </Layout>
                }
            />
            )
        }
    </AuthConsumer>
);

export default LayoutRoute;
