import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import SearchInput from '../components/SearchInput';
import {deleteParam, parseDate, sortirMap} from '../utils/Utilities';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import {AuthConsumer} from '../utils/AuthContext';
import Img from 'react-image';
import label from '../message/Label'

const tableTypes = ['', 'bordered', 'striped', 'hover'];

class AdminPage extends BasePage{


  constructor(props) {
    super(props);
    this.state ={
        admins : [],
        admin:{},
        modalDeleteConfirm:false,
        ascending:true,
        sortir:'created',
        search:"",
        page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
        totalPage:0,
        totalElement:0,
        pageElement:0,

    }
  }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search,true)
            })
        }
    }


    componentDidMount() {
        super.componentDidMount();
        this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true)
    }

  fetchAll = (ascending, sortir, search, progressing) => {
    this.get(Global.API.ADMINS, {
      params:{
        ascending:ascending,
        sortir:sortir,
        search:search,
      }
    }, null, res=>{
        if(res.code === 200){
            this.setState({
                admins : res.data,
                totalPage: res.totalPage,
                totalElement: res.totalElement,
                pageElement: res.pageElement,
            })
        }
    }, progressing, true);
  }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Global.API.ADMIN_DELETE+"/"+this.state.admin.id, null, null, res => {
          if(res.code===200){
              this.setState({
                  category:null
              }, () => {
                  this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true)
              })
          }
      }, true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

    refreshAdmin = (ascending, sortir, progressing) => (
        this.setState({
            ascending:ascending,
            sortir:sortir
        }, () => {
            this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, progressing)
        })
    )


    render() {
        var i = 0 ;

    return (
        <AuthConsumer>
            {({admin, logout, login})=>(
                    <Page
                        title={label.Administrator}
                        breadcrumbs={[{ name: label.Administrator, active: true }]}
                        className="TablePage"
                    >
            {super.render()}
                    <ConfirmDialog
                        showing={this.state.modalDeleteConfirm}
                        title={label.DeleteConfirmationTitle}
                        message="Do you want to delete this admin item from your list ?"
                        okCallback={this.delete}
                        cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card className="mb-6">
                <CardHeader>{label.Administrator}</CardHeader>
                <CardBody>
                  <Row>
                        <Col md={3}>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                                <DropdownToggle
                                    caret
                                    color="white"
                                    className="text-capitalize m-1">
                                    {
                                        sortirMap(this.state.sortir)
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e=>(this.refreshAdmin(this.state.ascending, "created", true))}>{label.Created}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshAdmin(this.state.ascending, "firstname", true))}>{label.Name}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshAdmin(this.state.ascending, "role.name", true))}>Role Name</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshAdmin(this.state.ascending, "updated", true))}>{label.Updated}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                        <Col md={3}>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                                <DropdownToggle
                                    caret
                                    color="white"
                                    className="text-capitalize m-1">
                                    {
                                        this.state.ascending?label.Ascending:label.Descending
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e=>(this.refreshAdmin(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshAdmin(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                        <Col md={4}>
                            <SearchInput
                                placeholder={"Search admin name..."}
                                value={this.state.search}
                                onChange={e=>{
                                    this.setState({
                                        search:e.target.value
                                    }, () => {
                                        if(this.state.search===''){
                                            deleteParam(this.props, 'page')
                                        }
                                    })
                                }}
                                onEnter={e=>{
                                    if(this.state.search!==''){
                                        deleteParam(this.props, 'page')
                                    }
                                }}
                            />
                        </Col>
                        <Col md={2}>
                            <ButtonGroup className="float-right">
                                <Button color="primary" onClick={e=>{
                                    this.props.history.push('/adminDetail')
                                }}>{label.CreateNewAdmin}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                  <Row>
                      <Table responsive hover>
                          <thead>
                          <tr>
                              <th>#</th>
                              <th>{label.Fullname}</th>
                              <th>{label.Email}</th>
                              <th>{label.Role}</th>
                              <th>{label.Status}</th>
                              <th>{label.Image}</th>
                              <th>{label.Updated}</th>
                              <th>{label.Edit}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                              this.state.admins.map((item, index)=>(
                                  <tr key={item.id}>
                                      <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                      <td>{item.firstname+" "+(item.lastname!=null?item.lastname:"")}</td>
                                      <td>{item.email}</td>
                                      <td>{item.role.name}</td>
                                      <td>{item.adminStatus}</td>
                                      <td width="5%">{
                                          item.image!=undefined?
                                              <Img
                                                  className="img-thumbnail"
                                                  src={item.imageLink}
                                                  onClick={e=>{
                                                      this.setState({
                                                          imageLink:item.imageLink,
                                                          openPreview:true
                                                      })
                                                  }}
                                              />
                                              :
                                              "-"
                                      }</td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>
                                          {
                                              admin.id!=item.id&&(
                                                  <Button size="sm" color="primary" onClick={ e => (
                                                      this.props.history.push('/adminDetail?id='+item.id)
                                                  )}>
                                                      <MdEdit/>
                                                  </Button>
                                              )
                                          }
                                      </td>
                                  </tr>
                              ))
                          }
                          </tbody>
                      </Table>
                  </Row>
                </CardBody>
                  <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />

              </Card>
            </Col>
          </Row>
        </Page>
            )}
        </AuthConsumer>
    );
  }
}
export default Object.assign(AdminPage, {ENDPOINT : "/admins"})
